import { takeEvery, call, put } from "redux-saga/effects";
import { GET_MAINCATEGORIES, SET_MAINCATEGORIES } from "../actions";
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMainCategories = function* () {
  yield takeEvery(GET_MAINCATEGORIES, workerGetMainCategories);
};

function* workerGetMainCategories({ main_category_slug }) {
  try {
    var maincategoryslug = "";
    if (
      main_category_slug !== "" &&
      typeof main_category_slug !== "undefined" &&
      typeof main_category_slug !== undefined
    ) {
      maincategoryslug = "&main_category_slug=" + main_category_slug;
    }
    const uri =
      apiUrlV2 +
      "products/productMainCategories?app_id=" +
      appId +
      maincategoryslug;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MAINCATEGORIES, value: resultArr });
  } catch {
    console.log("Get All Main Categories Failed");
  }
}
