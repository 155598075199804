import { SET_CATERING_PRODCUTDETAIL } from '../actions';

const cateringproductdetail = (state = [], action) => {
  switch (action.type) {
    case SET_CATERING_PRODCUTDETAIL:
      return [...action.value];
    default: return state;
  }
}

export default cateringproductdetail;