/* eslint-disable */
import React, { Component } from "react";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";

import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  cateringId,
  deliveryId,
} from "../Helpers/Config";

import { connect } from "react-redux";
var qs = require("qs");

import { GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL } from "../../actions";

import cookie from "react-cookies";

import rewardImg from "../../common/images/reward-wrk.png";

import facebook from "../../common/images/facebookreward.svg";
import instagram from "../../common/images/instagramreward.svg";

import iconshare from "../../common/images/icon-share.png";

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerdetails: "",
    };
    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }
  componentDidMount() {
    this.getActivityCounts();
    $("#dvLoading").fadeOut(2000);
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["reward", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
    $("#dvLoading").fadeOut(2000);
    var params = "&status=A&refrence=" + cookie.load("UserId");
    this.props.getCustomerDetail(params);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customerdetail !== this.props.customerdetail) {
      if (nextProps.customerdetail[0].status === "ok") {
        this.setState({
          customerdetails: nextProps.customerdetail[0].result_set,
        });
      }
    }
  }

  copyCurrentURL(text, type, e) {
    e.stopPropagation();
    e.preventDefault();
    var dummy = document.createElement("textArea");
    document.body.appendChild(dummy);
    dummy.value = type === "code" ? text : baseUrl + "rfcode/" + text;
    var range, selection;
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      range = document.createRange();
      range.selectNodeContents(dummy);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      dummy.setSelectionRange(0, 999999);
    } else {
      dummy.select();
    }

    document.execCommand("copy");
    document.body.removeChild(dummy);
    var toolBx = type === "code" ? "copied-code" : "copied-url";
    $("." + toolBx)
      .fadeIn()
      .delay(1000)
      .fadeOut();
  }

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*setTimeout(function () {
		$('#dvLoading').remove();
		}, 500);*/
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>My Account</h3>
            <p class="acc-para">
              {" "}
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout"></div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section account_sec rewards-newdesign">
                    <Sidebar pageName="referral" />
                    <div class="box_in accsec_right">
                      <div class="account_sec">
					  {cookie.load("userAccountType") !== "2" ? (
                        <div className="accsec_right">
                          <div className="mainacc_mobrow">
                            <div className="tab_sec main_tabsec_inner">
                              <div className="myacc_filter">
                                <div className="reward-maindiv">
                                  <div className="reward-innerdiv">
                                    <h3>Refer a friend. Get rewarded.</h3>
                                    <div className="reward-innerdiv-lhs">
                                      <ul>
                                        <li>
                                          <div className="reward-inner-number">
                                            {" "}
                                            <span>1</span>
                                          </div>
                                          <div className="reward-inner-content">
                                            {" "}
                                            <p>
                                              Share your personal referral code
                                            </p>{" "}
                                          </div>
                                        </li>

                                        <li>
                                          <div className="reward-inner-number">
                                            {" "}
                                            <span>2</span>
                                          </div>
                                          <div className="reward-inner-content">
                                            {" "}
                                            <p>
                                              Friends get 5 points each upon
                                              signing up
                                            </p>{" "}
                                          </div>
                                        </li>

                                        <li>
                                          <div className="reward-inner-number">
                                            {" "}
                                            <span>3</span>
                                          </div>
                                          <div className="reward-inner-content">
                                            {" "}
                                            <p>
                                              You get 5 points for each
                                              successful referral
                                            </p>{" "}
                                          </div>
                                        </li>
                                      </ul>
                                      <div className="reward-bottom-content">
                                        <p>
                                          *Referral codes are not valid when
                                          used together with other ongoing
                                          discounts or promotions.
                                        </p>
                                      </div>
                                    </div>

                                    <div className="reward-innerdiv-rsh">
                                      <div className="reward-rsh-imgdiv">
                                        <img src={rewardImg} />
                                      </div>
                                    </div>

                                    {this.state.customerdetails !== "" && (
                                      <div className="reward-overall-bottom">
                                        <div className="reward-bottom-buttondiv reward-bottom-leftdiv">
                                          <ul>
                                            <li className="reward-code-heading">
                                              Your referral code
                                            </li>
                                            <li className="reward-bottom-span">
                                              <span className="reward-bottom-left">
                                                {
                                                  this.state.customerdetails
                                                    .customer_referrar_code
                                                }
                                              </span>
                                              <span
                                                className="reward-bottom-right"
                                                onClick={this.copyCurrentURL.bind(
                                                  this,
                                                  this.state.customerdetails
                                                    .customer_referrar_code,
                                                  "code"
                                                )}
                                              >
                                                copy
                                              </span>
                                            </li>
                                            <li
                                              className="copied-code"
                                              style={{ display: "none" }}
                                            >
                                              Copied
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="reward-bottom-buttondiv reward-bottom-rightdiv hidden">
                                          <ul>
                                            <li className="reward-bottom-heading">
                                              lnvite friends now
                                            </li>
                                            <li className="reward-bottom-icon">
                                              <span className="icon-link">
                                                <a
                                                  href="https://www.instagram.com/"
                                                  target="_blank"
                                                >
                                                  <img
                                                    alt="Facebook"
                                                    src={instagram}
                                                  />
                                                </a>
                                              </span>
                                            </li>

                                            <li className="reward-bottom-icon">
                                              <span className="icon-link">
                                                <a
                                                  href="https://www.facebook.com/"
                                                  target="_blank"
                                                >
                                                  <img
                                                    alt="Facebook"
                                                    src={facebook}
                                                  />
                                                </a>
                                              </span>
                                            </li>

                                            <li className="reward-bottom-icon">
                                              <span className="icon-link">
                                                <a
                                                  href=""
                                                  onClick={this.copyCurrentURL.bind(
                                                    this,
                                                    this.state.customerdetails
                                                      .customer_referrar_code,
                                                    "url"
                                                  )}
                                                >
                                                  <img
                                                    alt="Copy Link"
                                                    src={iconshare}
                                                  />
                                                </a>
                                              </span>
                                            </li>
                                          </ul>
                                          <ul>
                                            <li
                                              className="copied-url"
                                              style={{ display: "none" }}
                                            >
                                              Copied
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
						) : (
                          <div className="accsec_right">
                            <div className="mainacc_mobrow">
                              <div className="tab_sec main_tabsec_inner">
                                <div className="profile-info-div">
                                  <h4 className="form_grouptt">
                                    You are logged in as a guest, to view your
                                    account information please login into your
                                    account with your username and password.
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};
Referral.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referral)
);
