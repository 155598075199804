import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BLOG, SET_BLOG } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetBlog = function* () {
  yield takeEvery(GET_BLOG, workerGetBlog);
};

function* workerGetBlog() {
  try {
    const uri = apiUrl + "blog/blog_list?app_id=" + appId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BLOG, value: resultArr });
  } catch (e) {
    console.log(e, "Get Blog Failed");
  }
}
