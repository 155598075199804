/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { appId, apiUrlV2, deliveryId, noimage } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  shorten,
  createScriptForAddToCart
} from "../../Helpers/SettingHelper";

var qs = require("qs");
var Parser = require("html-react-parser");

import { GET_HIGHLIGHTPROLIST, GET_FAVOURITE } from "../../../actions";
import cartLike from "../../../common/images/heart-black.svg";
import cartLikeActive from "../../../common/images/heart-bg-red.svg";
import { isNull } from "underscore";
import OwlCarousel from "react-owl-carousel2";

class PromoProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      favproductID: [],
      favdeleteproductID: [],

    };
  }

  componentWillMount() {
    this.props.getHighlightList();
  }

  addFavourite(productID, fav_flag) {
    var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
    if (customerId !== "") {
    var formData = {
      app_id: appId,
      product_id: productID,
      fav_flag: fav_flag,
      customer_id: cookie.load("UserId"),
    };
    var favproductID = this.state.favproductID;
    var favdeleteproductID = this.state.favdeleteproductID;
    if (fav_flag === "Yes") {
      favproductID.push(productID);
      const index1 = favdeleteproductID.indexOf(productID);
      if (index1 > -1) {
        favdeleteproductID.splice(index1, 1);
      }
    } else {
      const index = favproductID.indexOf(productID);
      if (index > -1) {
        favproductID.splice(index, 1);
      }
      favdeleteproductID.push(productID);
    }
    this.setState(
      { favproductID: favproductID, favdeleteproductID: favdeleteproductID }  
    );

    var postObject = qs.stringify(formData);
    this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }


  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);

    if(actionFlg === "decr" && proqtyInput == 1){
      $("#proIndex-" + indxFlg)
          .find(".smiple_product_lk")
          .show();
      $("#proIndex-" + indxFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + indxFlg)
      .find(".product-list-main-div")
      .removeClass('add-cart');        
    }
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        $("#proIndex-" + IndexFlg)
        .find(".product-list-main-div")
        .addClass('add-cart');          
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
			var userAccountType =
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
		  login_type: userAccountType,
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + IndexFlg)
            .find(".product-list-main-div")
            .removeClass('add-cart');            
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
              createScriptForAddToCart();
            }
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        // $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.history.push(
          "/menu/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productDetail.product_slug
        );
        // this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let highlightArr = this.props.highlightproduct;
    let highlightlist = [];
    let highlightimagesource = "";
    let tagimagesource = "";

    if (Object.keys(highlightArr).length > 0) {
      if (highlightArr[0].status === "ok") {
        highlightlist = highlightArr[0].result_set;
        highlightimagesource = highlightArr[0].common.image_source;
        tagimagesource = highlightArr[0].common.tag_image_source;
      }
    }

    const settingsJourney = {
      items: 4,
      autoplay: false,
      margin: 18,
      loop: false,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        580: {
          items: 2,
        },
        780: {
          items: 3,
        },
        1280: {
          items: 4,
        },
      },
    };

    return (
      <section className="featuredpro-section">
        {Object.keys(highlightlist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              {this.props.homepromoBlk}
              <div className="featured-products-main">
                <ul className="products-list-ulmain">
                  <OwlCarousel options={settingsJourney}>
                  {highlightlist.map((highlight, index) => {
                    var prodivId = "proIndex-" + highlight.product_primary_id;
                    var comboProId = "comboPro-" + highlight.product_slug;
                      return (
                        <li
                          className={highlight.product_tag_info !== "" &&
                            highlight.product_tag_info !== null ? "products-single-li offer_tag_show":"products-single-li"}
                          id={prodivId}
                          key={index}
                        >
                        <div
                          className={
                            highlight.product_tag_info !== "" &&
                            highlight.product_tag_info !== null
                              ? "box-outer product-list-main-div"
                              : "product-list-main-div"
                          }
                        >

                          {highlight.product_tag_info !== "" &&
                            highlight.product_tag_info !== null && (
                              <div className="sale-flag-sash">
                                <span className="sale-text">{highlight.product_tag_info}</span>
                              </div>
                            )}

                            {this.state.customerId !== "" ? (
                              <div>
                                {this.state.favdeleteproductID.length > 0 &&
                                this.state.favdeleteproductID.indexOf(
                                  highlight.product_primary_id
                                ) >= 0 ? (
                                  <a
                                    href={void 0}
                                    className="like"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      highlight.product_primary_id,
                                      "Yes"
                                    )}
                                  >
                                    <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                  </a>
                                ) : (!isNull(highlight.fav_product_primary_id) &&
                                    typeof highlight.fav_product_primary_id !==
                                      undefined &&
                                    typeof highlight.fav_product_primary_id !==
                                      "undefined") ||
                                  this.state.favproductID.indexOf(
                                    highlight.product_primary_id
                                  ) >= 0 ? (
                                  <a
                                    href={void 0}
                                    className="like active"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      highlight.product_primary_id,
                                      "No"
                                    )}
                                  >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                  </a>
                                ) : (
                                  <a
                                    href={void 0}
                                    className="like"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      highlight.product_primary_id,
                                      "Yes"
                                    )}
                                  >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                  </a>
                                )}
                              </div>
                            ) : (
                              <div>
                                <a
                                  href={void 0}
                                  className="like"
                                  onClick={this.addFavourite.bind(
                                    this,
                                    highlight.product_primary_id,
                                    "Yes"
                                  )}
                                >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                </a>
                              </div>
                            )}

                          <div className="products-image-div">
                            {highlight.product_thumbnail !== "" ? (
                              <img
                                src={
                                  highlightimagesource +
                                  "/" +
                                  highlight.product_thumbnail
                                }
                                alt={
                                  highlight.product_alias !== ""
                                    ? stripslashes(highlight.product_alias)
                                    : highlight.product_name
                                    ? stripslashes(highlight.product_name)
                                    : ""
                                }
                              />
                            ) : (
                              <img
                                src={noimage}
                                alt={
                                  highlight.product_alias !== ""
                                    ? stripslashes(highlight.product_alias)
                                    : highlight.product_name
                                    ? stripslashes(highlight.product_name)
                                    : ""
                                }
                              />
                            )}
                          </div>

                          <div className="product-info-div">
                            <div className="product-title-maindiv">
                              <div className="product-title">
                                <h3>
                                  {highlight.product_alias !== ""
                                    ? stripslashes(highlight.product_alias)
                                    : highlight.product_name
                                    ? stripslashes(highlight.product_name)
                                    : ""}
                                </h3>
                              </div>
                              <div className="product-short-description">
                              <div className="product-item-img">
                              {highlight.product_tag.length > 0 && (<>
                                  {highlight.product_tag.map((producttag, index1) => {
                                    return (
                                      <img src={
                                        tagimagesource +
                                        "/" +
                                        producttag.pro_tag_image
                                    }/>
                                    );
                                  })}
                                  </>
                              )}
                              </div>
                                {highlight.product_short_description != "" &&
                                highlight.product_short_description != null
                                  ? Parser(
                                      stripslashes(shorten(highlight.product_short_description))
                                    )
                                  : Parser("")}
                              </div>
                            </div>
                            {/*highlight.product_cost > 0 && (
                              <h4 className="original-price">
                                {showPriceValue(highlight.product_cost)}
                              </h4>
                            )*/}
                            <div className="products-ordernow-action">
                              <div className="product-price">
                                {highlight.product_specialprice_applicable ===
                                "yes"
                                  && ( parseFloat(highlight.product_price) > 0 ? (
                                      <span className="price_disc">
                                        {showPriceValue(
                                          highlight.product_price
                                        )}
                                      </span>
                                    )
                                  : parseFloat(highlight.product_cost) > 0 && (
                                      <span className="price_disc">
                                        {showPriceValue(highlight.product_cost)}
                                      </span>
                                    ))}
                                <h3>
                                  {highlight.product_specialprice_applicable ===
                                  "yes"
                                    ? showPriceValue(
                                        highlight.product_special_price,
                                        "Y"
                                      )
                                    : showPriceValue(highlight.product_price)}
                                </h3>
                              </div>
                              <div className="addcart_row addcart_done_maindiv">
                                <div className="addcart-row-child">
                                  <div className="qty_bx">
                                    <span
                                      className="qty_minus"
                                      onClick={this.proQtyAction.bind(
                                        this,
                                        highlight.product_primary_id,
                                        "decr"
                                      )}
                                    >
                                      -
                                    </span>
                                    <input
                                      type="text"
                                      className="proqty_input"
                                      readOnly
                                      value="1"
                                    />
                                    <span
                                      className="qty_plus"
                                      onClick={this.proQtyAction.bind(
                                        this,
                                        highlight.product_primary_id,
                                        "incr"
                                      )}
                                    >
                                      +
                                    </span>
                                  </div>
                                  <button
                                    className="button btn btn_black order_done"
                                    onClick={this.addToCartSimple.bind(
                                      this,
                                      highlight,
                                      "done"
                                    )}
                                  >
                                    Done
                                  </button>
                                </div>
                              </div>

                              {highlight.product_stock > 0 ||
                              highlight.product_stock === null ? (
                                highlight.product_type === "1" ? (
                                  <a
                                    href="/"
                                    onClick={this.viewProDetail.bind(
                                      this,
                                      highlight
                                    )}
                                    title="Product Details"
                                    id={comboProId}
                                    className="button order_nowdiv compo_product_lk"
                                  >
                                    Add to Cart
                                  </a>
                                ) : (
                                  <a
                                    href="/"
                                    onClick={this.viewProDetail.bind(
                                      this,
                                      highlight
                                    )}
                                    title="Product Details"
                                    id={comboProId}
                                    className="button order_nowdiv compo_product_lk"
                                  >
                                    Add to Cart
                                  </a>
                                )
                              ) : (
                                <a
                                  className="button disabled disbl_href_action"
                                  href="/"
                                >
                                  Sold Out
                                </a>
                              )}
                            </div>
                          </div>
                          </div>
                        </li>
                      );
                  })}
                  </OwlCarousel>
                </ul>
              </div>
              <div className="home-bottom-link">
                <Link to={"/menu"}>Shop More</Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    highlightproduct: state.highlightproductlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHighlightList: () => {
      dispatch({ type: GET_HIGHLIGHTPROLIST });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(PromoProducts);
