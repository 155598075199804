/* eslint-disable */

import React, { Component } from "react";
import validator from "validator";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import Changepassword from "./Changepassword";
import Billingaddress from "./Billingaddress";
import {
  addressFormat,
  showAlert,
  showCustomAlert,
  showLoader,
  hideLoader,
  stripslashes
} from "../Helpers/SettingHelper";
import { appId, apiUrl, mapcountry } from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";
import { includes } from 'lodash';
import Autocomplete from "../Layout/Autocomplete";
import {
  GET_CHANGEPASSWORD,
  GET_ALLUSERSECADDRDATA,
  ADD_USERSECADDRDATA,
  GET_STATIC_BLOCK,
  GET_GLOBAL_SETTINGS
} from "../../actions";

import { connect } from "react-redux";

import $ from "jquery";
var Parser = require("html-react-parser");

import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Sidebar from "./Sidebar";
import like from "../../common/images/like.png";
import hiHndImage from "../../common/images/hi.png";
import hiHndarkImg from "../../common/images/hi_white.png";

import branzeImg from "../../common/images/myaccount-1.png";
import silverImg from "../../common/images/myaccount-2.png";
import goldImg from "../../common/images/myaccount-3.png";

var dateFormat = require("dateformat");
var qs = require("qs");

var currentAppId =  [ "93EDF976-7242-4864-B57E-7B49306521AC", "E66D5AAF-9077-4885-B379-02D3FCB8BB16"];

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern)
    ? null
    : "This is not a valid mobile number.";
const isPostal = (postal) =>
  postal.match(phonenumberPattern) || postal === "" || postal == null
    ? null
    : "This is not a valid postal code.";

const dobpattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

function validationConfig(props) {
  const { firstname, lastname, email, mobile, birthdate, postal } =
    props.fields;

  return {
    fields: ["firstname", "lastname", "email", "mobile", "birthdate"],

    validations: {
      firstname: [[isEmpty, firstname]],
      //lastname: [[isEmpty, lastname]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      birthdate: [[isEmpty, birthdate]],
      postal: [[isPostal, postal]],
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear());
    this.state = {
      status: "Loading",
      Maxdate: Maxdate,
      secAddrData: [],
      address_added: "no",
      birthdate: "",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
      fieldsbillingaddress: {
        postalcode: "",
        addressline: "",
        floor_no: "",
        unit_no: "",
      },
      staticblacks: [],
      homeAboutBlk: "",
      openAddress:"",
      deliveryAddress: "",
      deliveryLat: "",
      deliveryLon: "",
      globalsettings: [],
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.changepassword = this.changepassword.bind(this);
    this.props.getSecondaryAddress();
    this.props.getStaticBlock();
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    /*axios.get(apiUrl + "customer/get_all_user_secondary_address?app_id=" + appId + "&status=A&refrence=" + cookie.load('UserId')).then(res => {
            if (res.data.status === "ok") {
                this.setState({ secAddrData: res.data.result_set });
            } else {
                this.setState({ secAddrData: '', secAddrData: [] });
            }
        }); */
  }

  componentWillReceiveProps(nextProps) {

   if (nextProps.staticblack !== this.state.staticblacks) {
      var homeAboutBlk = "";

      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "membership-popup") {
            homeAboutBlk = data.staticblocks_description;
            return "";
          }
        });
      }
      homeAboutBlk =
        homeAboutBlk !== "" && homeAboutBlk !== null
          ? Parser(homeAboutBlk)
          : homeAboutBlk;

      this.setState({
        staticblacks: nextProps.staticblack,
        homeAboutBlk: homeAboutBlk,
      });
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (nextProps.globalsettings[0].status === "ok") {
        this.setState({
          globalsettings: nextProps.globalsettings[0].result_set,
        });
      }
    }


    if (this.state.secAddrData !== nextProps.secondaryaddress) {
      if (this.state.address_added === "yes") {
        $(".postalcode, .address_line, .floor_no, .unit_no").val("");
        hideLoader("billing-addrs-sbmt", "class");
        window.$.magnificPopup.close();
        showCustomAlert(
          "success",
          "Nice! Your billing address added successfully."
        );
      }
      this.setState({
        secAddrData: nextProps.secondaryaddress,
        address_added: "no",
      });
    }

    // if (nextProps.changepassword !== this.props.changepassword) {
    //   //  const {changepassword} = nextProps.changepassword;
    //   $(".old_password").val("");
    //   $(".new_password").val("");
    //   $(".confirm_password").val("");
    //   hideLoader("change-pass-sbmt", "class");
    //   if (nextProps.changepassword[0].status === "ok") {
    //     showAlert("Success", "Password changed successfully!");
    //   } else {
    //     if (nextProps.changepassword[0].form_error) {
    //       showAlert("Success", nextProps.changepassword[0].form_error);
    //     } else {
    //       showAlert("Success", nextProps.changepassword[0].message);
    //     }
    //   }
    //   window.$.magnificPopup.open({
    //     items: {
    //       src: ".alert_popup",
    //     },
    //     type: "inline",
    //   });
    // }
  }

  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    cookie.save("birthdate", dateval, { path: "/" });
    this.setState({ birthdate: datevalue });
    this.handleChange("birthdate", datevalue);
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  fieldAddChange = (field, value) => {
    this.setState(
      update(this.state, { fieldsbillingaddress: { [field]: { $set: value } } })
    );
  };

  fieldautoAddChange = (field, value) => {
    this.setState(
      update(this.state, { fieldsbillingaddress: { [field]: { $set: value } } })
    );
  };

  /* To delete the secondary address */
  deleteSecAddr(addrId) {
    var postObject = {
      app_id: appId,
      type: "web",
      secondary_address_id: addrId,
      refrence: cookie.load("UserId"),
    };

    axios
      .post(
        apiUrl + "customer/secondary_address_remove",
        qs.stringify(postObject)
      )
      .then((response) => {
        if (response.data.status === "ok") {
          this.props.getSecondaryAddress();
          window.$.magnificPopup.close();
          showCustomAlert("success", "Address deleted successfully!");
          // showAlert("Success", "Address deleted successfully!");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
        }
      })
      .catch(function (error) {});
  }

  /* Get Secondary Address Details */
  secondaryAddresslist() {
    if (this.state.secAddrData.length > 0) {
      return this.state.secAddrData.map((addr, index) => (
        <li key={index}>
          <a href="javascript:;">
            {addressFormat(
              addr.unit_code,
              addr.unit_code2,
              addr.address,
              addr.country,
              addr.postal_code
            )}
          </a>
          <span
            onClick={() => {
              this.deleteSecAddr(addr.secondary_address_id);
            }}
          ></span>
        </li>
      ));
    } else {
      return <li>No address found</li>;
    }
  }

  /*loadPostalAddress(postal_code,address1) {*/
  loadPostalAddress() {
    var postal = $(".customer_postal_code").val();
    document.getElementById("spn-postalcode-error").innerHTML = "";
    if (postal.length > 5) {
      axios
        .get(
          apiUrl +
            "/settings/get_address?app_id=" +
            appId +
            "&zip_code=" +
            postal
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var address_name =
              res.data.result_set.zip_buno +
              " " +
              res.data.result_set.zip_sname;
            /*$("#customer_address_line1").val(address_name);
                    this.setState({postal :postal });
                    this.setState({address1 :address_name });*/
            this.props.onChange("address1", address_name);
          } else {
            document.getElementById("spn-postalcode-error").innerHTML =
              '<span class="error">This is not a valid postal code.</span>';
            /*$(".customer_postal_code").val('');
                    $("#customer_address_line1").val('');*/
            this.props.onChange("address1", "");
          }
        });
    } else {
      $(".customer_postal_code").val(postal);
    }
  }

  /* Change Password */
  changepassword() {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      passwordtype: "PIN",
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
  }

  /* Change Password */
  changeaddress = () => {
    if(mapcountry==="sg") {
      var postalcode = $(".postalcode").val();
      var addressline = $(".address_line").val();
      var unitnumber1 = $(".floor_no").val();
      var unitnumber2 = $(".unit_no").val();
    }else {
      var postalcode = '';
      var addressline = this.state.deliveryAddress;
      var latitude = this.state.deliveryLat;
      var longitude = this.state.deliveryLon;
      var unitnumber1 = $(".floor_no1").val();
      var unitnumber2 = $(".unit_no1").val();
    }
    /* postalcode !== "" &&  */
    
    if (addressline !== "") {
      showLoader("billing-addrs-sbmt", "class");
      var postArr = Array();
      postArr["postalcode"] = postalcode;
      postArr["addressline"] = addressline;
      postArr["unitnumber1"] = unitnumber1;
      postArr["unitnumber2"] = unitnumber2;
      postArr["latitude"] = latitude;
      postArr["longitude"] = longitude;
      this.setState(
        { address_added: "yes" },
        function () {
          this.props.addSecondaryAddress(postArr);
        }.bind(this)
      );
    }
  };

  handleChange = (item, value) => {
    this.props.onChange(item, value);
    this.setState({ ...this.state, [item]: value });
    var des = document.getElementsByClassName("birthdate");
  };


  openRefer(){
    console.log(343)
    window.$.magnificPopup.open({
      items: {
        src: "#refer-popup",
      },
      type: "inline",
    });
  }
  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var addressdetails = value.split("~~");
        this.setState(
          {
            deliveryAddress: addressdetails[0],
            deliveryLat: addressdetails[1],
            deliveryLon: addressdetails[2],
          },
          function () {
            $("#location-error").hide().html("");
          }
        );
      }
    }
  };

  membership_image_display(){

    let customer_membership_displayname = "";

    var membership_image_url = '';
  

    if ( Object.keys(this.props.customerdetail).length && this.props.customerdetail[0].status === "ok") {

      customer_membership_displayname = this.props.customerdetail[0].result_set.customer_membership_displayname;

      var membership_image_array = this.props.customerdetail[0].result_set.customer_membership_images;

      membership_image_url = this.props.customerdetail[0].common.membership_image_source;

      var bronze_image = '';
      var sliver_image = '';
      var gold_image = '';

      membership_image_array.map((item,index)=>{
      
        if(item.membership_display_name === 'Gold'){

          gold_image = item.membership_image;
        }

        if(item.membership_display_name === 'Silver'){

          sliver_image = item.membership_image;

        }

        if(item.membership_display_name === 'Bronze'){

          bronze_image = item.membership_image;

        }

      });


    }

    return ( <ul>
      <li className={customer_membership_displayname == 'Bronze' && 'active'}>
        <a href="#">
          <div className="">
            <img src={bronze_image !== '' ? membership_image_url + bronze_image: branzeImg} />
          </div>
        </a>
      </li>
      <li className={customer_membership_displayname == 'Silver' && 'active'}>
        <a href="#">
          <div className="">
            <img src={sliver_image !=='' ? membership_image_url + sliver_image : silverImg} />
          </div>
        </a>
      </li>
      <li className={customer_membership_displayname == 'Gold' && 'active'}>
        <a href="#">
          <div className="">
            <img src={gold_image !=='' ? membership_image_url + gold_image : goldImg} />
          </div>
        </a>
      </li>
    </ul>)

  }

  render() {
    var displaySts =
      this.state.defaultDispaly === "" ? "none" : this.state.defaultDispaly;

    const {
      fields,
      activityPoints,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;

    let PreviousOrderAddressTitle,
      errMsgFirstname,
      errMsgLastname,
      errMsgEmail,
      errMsgMobile,
      errMsgPostal,
      errMsgbirthdate,
      errMsgPostalCode;
    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstname = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }

    /*if ($validation.lastname.error.reason !== undefined) {
      errMsgLastname = $validation.lastname.show && (
        <span className="error">{$validation.lastname.error.reason}</span>
      );
    }*/

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.birthdate.error.reason !== undefined) {
      errMsgbirthdate = $validation.birthdate.show && (
        <span className="error">{$validation.birthdate.error.reason}</span>
      );
    }
    if ($validation.postal.error.reason !== undefined) {
      if ($("#spn-postalcode-error .error").length === 0) {
        errMsgPostalCode = $validation.postal.error !== undefined && (
          <span className="error">{$validation.postal.error.reason}</span>
        );
      }
    }

    var genderlabel = "";
    var gender = "";
    if (fields.gender == "M") {
      genderlabel = "Male";
      gender = "M";
    } else if (fields.gender == "F") {
      genderlabel = "Female";
      gender = "F";
    } else if (fields.gender == "O") {
      genderlabel = "Unspecified";
      gender = "O";
    }

    var genderBoxHtml = "<option value='M'>Male</option>";
    genderBoxHtml += "<option value='F'>Female</option>";
    genderBoxHtml += "<option value='O'>Unspecified</option>";
    var genderSelectDropDown = Parser(genderBoxHtml);

    var joined_date = "";
    if (fields.joinedOn !== "" && fields.joinedOn != undefined) {
      var joinedDateArr = fields.joinedOn.split(" ");
      var joinedDateObj =
        joinedDateArr[0] !== "" ? new Date(joinedDateArr[0]) : new Date();
      joined_date = format(joinedDateObj, "dd/MM/yyyy");
    }
    // var birthdate = new Date();
    var birthdate = "";
    if (this.state.birthdate !== "") {
      birthdate = this.state.birthdate;
    } else if (
      fields.birthdate !== "" &&
      fields.birthdate !== "0000-00-00" &&
      fields.birthdate !== "00/00/0000"
    ) {
      birthdate = new Date(fields.birthdate);
      this.setState({ birthdate: birthdate });
    }


    if (typeof this.props.customerdetail !== undefined) {
      let customer_membership_type = "";
      let customer_membership_type_display = "";
      let customer_unique_id = "";
      let calc_kakis_perc = 0;
      let calc_kakis_perc_display = "";
      let customer_membership_displayname = "";

      if (
        Object.keys(this.props.customerdetail).length &&
        this.props.customerdetail[0].status === "ok"
      ) {
        customer_membership_type =
          this.props.customerdetail[0].result_set.customer_membership_type;
        customer_unique_id =
          this.props.customerdetail[0].result_set.customer_unique_id;

        let membership_max_amount =
          this.props.customerdetail[0].result_set.membership_max_amount;
        let membership_spent_amount =
          this.props.customerdetail[0].result_set.membership_spent_amount;

        let membership_spent_msg =
          this.props.customerdetail[0].result_set.membership_spent_msg;

        customer_membership_displayname = this.props.customerdetail[0].result_set
          .customer_membership_displayname;


        if (parseInt(customer_unique_id)) {
          customer_unique_id = (
            <div className="user-id">
              <span>User Id</span> - {customer_unique_id}
            </div>
          );
        } else {
          customer_unique_id = "";
        }

        if (parseInt(membership_max_amount)) {
          calc_kakis_perc =
            (membership_spent_amount / membership_max_amount) * 100;
        }
        if (membership_max_amount) {
          calc_kakis_perc_display = (
            <div class="member-prog-bar">
              <div class="member-progress-2">
                <div class="member-bar-2 member-bar-width-2" style={{ width: calc_kakis_perc + "%" }}></div>
              </div>
              <p class="member-help-block">{membership_spent_msg}</p>
            </div>
          );
        }
      }

      return (
        <div className="container-one">
          <div className="tab-content">
            <div className="tab-pane account-tab-section fade in active">
              <h4 className="tab_mobtrigger active"></h4>
              <div className="tab_mobrow main_tabin">
                <div className="account_sec">
                  <Sidebar pageName="myaccount" />
                  <div className="box_in accsec_right">
                    <div className="account_sec maccont_sec">
                      <div className={this.state.globalsettings.client_enable_membership ==='1' ?"maccont_membershipmaindiv" : "maccont_membership no-membership"}>
                          <div className="welcome-title">
                            <h1>
                              <div>
                                <span>Welcome</span>
                                <br />
                                {fields.firstname} {fields.lastname}
                              </div>
                              <img className="white-thmimg" src={hiHndImage} />
                              <img className="dark-thmimg" src={hiHndarkImg} />
                            </h1>
                            { this.state.globalsettings.client_enable_membership==='1' && cookie.load("userAccountType") !== "2" && (
                            <a href="#refer-popup" onClick={this.openRefer.bind(this)} className="open-popup-link"  title="Refer Now">View Membership Benefits</a>
                            )}
                          </div>
                          { this.state.globalsettings.client_enable_membership==='1' && cookie.load("userAccountType") !== "2" && (
                          <div className="maccont_memberimagediv">
                            {this.membership_image_display()}
                            {calc_kakis_perc_display}
                          </div>
                          )}
                        </div>
						
						{cookie.load("userAccountType") !== "2" ? (
                      <div className="accsec_right">
                        <div className="acc-inform">
                          {/* profile-info-div - end */}
                          <div className="profile-info-div">
                            <h4 className="form_grouptt">
                              Your Account Information
                            </h4>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className={
                                      fields.firstname != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>Name</label>
                                    <input
                                      type="text"
                                      id="firstname"
                                      className="form-control input-focus"
                                      value={fields.firstname || ""}
                                      {...$field("firstname", (e) =>
                                        onChange("firstname", e.target.value)
                                      )}
                                    />
                                    {errMsgFirstname}
                                  </div>
                                </div>

                                { includes(currentAppId, appId) === true ?
                                  <div className="col-md-6">
                                    <div
                                      className={
                                        fields.mobile != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <label>Mobile Number</label>
                                      <input
                                        type="text"
                                        name="customer_phone"
                                        value={fields.mobile || ""}
                                        maxLength={mapcountry==="sg"?"8":""}
                                        {...$field("mobile", (e) =>
                                          onChange("mobile", e.target.value)
                                        )}
                                        className="form-control input-focus"
                                      />
                                      {errMsgMobile}
                                    </div>
                                  </div>:
                                  <div className="col-md-6">
                                  <div
                                    className={
                                      fields.lastname != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>Last Name</label>
                                    <input
                                      type="text"
                                      value={fields.lastname || ""}
                                      {...$field("lastname", (e) =>
                                        onChange("lastname", e.target.value)
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {errMsgLastname}
                                  </div>
                                </div> }

                              </div>
                            </div>
                          { includes(currentAppId, appId) === false ?

                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className={
                                      fields.nickname != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>PreferredName</label>
                                    <input
                                      type="text"
                                      name="customer_nick_name"
                                      value={fields.nickname || ""}
                                      {...$field("nickname", (e) =>
                                        onChange("nickname", e.target.value)
                                      )}
                                      className="form-control input-focus"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                    <div
                                      className={
                                        fields.mobile != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <label>Mobile Number</label>
                                      <input
                                        type="text"
                                        name="customer_phone"
                                        value={fields.mobile || ""}
                                        maxLength={mapcountry==="sg"?"8":""}
                                        {...$field("mobile", (e) =>
                                          onChange("mobile", e.target.value)
                                        )}
                                        className="form-control input-focus"
                                      />
                                      {errMsgMobile}
                                    </div>
                                  </div>
                              </div>
                            </div>:''}
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className={
                                      fields.email != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>Email Address</label>
                                    <input
                                      type="email"
                                      name="customer_email"
                                      value={fields.email || ""}
                                      {...$field("email", (e) =>
                                        onChange("email", e.target.value)
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {errMsgEmail}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className={
                                          birthdate != ""
                                            ? "focus-out focused"
                                            : "focus-out"
                                        }
                                      >
                                        <label>Birthday</label>
                                        { includes(currentAppId, appId) === true ?
                                        <div className="react_datepicker">
                                          <DatePicker
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                            selected={
                                              this.state.birthdate !== ""
                                                ? this.state.birthdate
                                                : undefined
                                            }
                                            maxDate={this.state.Maxdate}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={this.handleChangeDate}
                                          />

                                          {errMsgbirthdate}
                                        </div> :
                                        <div className="react_datepicker">
                                          <DatePicker
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                            selected={
                                              this.state.birthdate !== ""
                                                ? this.state.birthdate
                                                : undefined
                                            }
                                            maxDate={this.state.Maxdate}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={this.handleChangeDate}
                                            disabled={
                                              this.state.birthdate !== ""
                                                ? "true"
                                                : undefined
                                            }
                                          />

                                          {errMsgbirthdate}
                                        </div> }
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="re_select">
                                        {(gender !== "" ||
                                          fields.gender !== "") && (
                                          <Select
                                            defaultValue={{
                                              value: gender,
                                              label: genderlabel,
                                            }}
                                            onChange={onChange.bind(
                                              this,
                                              "gender"
                                            )}
                                            options={[
                                              { value: "M", label: "Male" },
                                              { value: "F", label: "Female" },
                                              {
                                                value: "O",
                                                label: "Unspecified",
                                              },
                                            ]}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div id="changepw-form-msg"></div>
                              </div>
                            </div>
                          </div>
                          {/* profile-info-div - end */}

                          {/* address-info-div - start */}
                          <div className="address-info-div">
                          {mapcountry==="sg" && <h4 className="form_grouptt">Address</h4>}
                          {mapcountry==="sg" &&
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className={
                                      fields.postal != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>Postal Code</label>
                                    <input
                                      type="text"
                                      maxLength="6"
                                      id="postal-code"
                                      value={fields.postal || ""}
                                      {...$field("postal", (e) =>
                                        onChange("postal", e.target.value)
                                      )}
                                      onBlur={this.loadPostalAddress.bind(this)}
                                      className="form-control input-focus customer_postal_code"
                                    />
                                    {errMsgPostalCode}
                                    <div id="spn-postalcode-error"></div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div
                                    className={
                                      fields.address1 != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label>Address Line 1</label>
                                    <input
                                      type="text"
                                      id="customer_address_line1"
                                      className="form-control input-focus"
                                      value={stripslashes(fields.address1) || ""}
                                      {...$field("address1", (e) =>
                                        onChange("address1", e.target.value)
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>}
                            <div className="form-group">
                              <div className="row">
                              {mapcountry==="sg" &&
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className={
                                          fields.unitnumber1 != ""
                                            ? "focus-out focused"
                                            : "focus-out"
                                        }
                                      >
                                        <label>Unit Number 1</label>
                                        <input
                                          type="text"
                                          className="form-control input-focus"
                                          value={fields.unitnumber1 || ""}
                                          {...$field("unitnumber1", (e) =>
                                            onChange(
                                              "unitnumber1",
                                              e.target.value
                                            )
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className={
                                          fields.unitnumber2 != ""
                                            ? "focus-out focused"
                                            : "focus-out"
                                        }
                                      >
                                        <label>Unit Number 2</label>
                                        <input
                                          type="text"
                                          className="form-control input-focus"
                                          value={fields.unitnumber2 || ""}
                                          {...$field("unitnumber2", (e) =>
                                            onChange(
                                              "unitnumber2",
                                              e.target.value
                                            )
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="myaccount_update button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.$submit(onValid, onInvalid);
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* address-info-div - end */}
                        </div>

                        <div className="other-add">
                          <h4 className="form_grouptt">
                            Other Address{" "}
                            <a
                              href="#billing-address-popup"
                              data-effect="mfp-zoom-in"
                              onClick={(e) => {
                                this.setState({openAddress:true})
                              }}
                              className="open-popup-link add-address-more"
                            >
                              <i
                                className="fa fa-plus-circle"
                                data-unicode="f055"
                              ></i>
                            </a>
                          </h4>
                          <div className="other-add-body mCustomScrollbar">
                            <div>
                              <ul className="other-add-row">
                                {this.secondaryAddresslist()}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
					  ) : (
                        <div className="accsec_right">
                          <div className="acc-inform">
                            <div className="profile-info-div">
                              <h4 className="form_grouptt">
                                You are logged in as a guest, to view your
                                account information please login into your
                                account with your username and password.
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		  
		  {cookie.load("userAccountType") !== "2" && (

          <div
            id="change-password-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Change PIN</h3>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChange}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
		  )}

<div
          id="refer-popup"
          className="white-popup mfp-hide popup_sec receipt_popup refer_popup"
        >
          <div className="redeem_bansec">
            <div className="promo-popup-image">
              
            </div>
            <div className="promo-earned-content">
              
              <div className="addcart_row">
                {this.state.homeAboutBlk}
              </div>
              
            </div>
          </div>
        </div>


          <div
            id="billing-address-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Billing Address</h3>
              {mapcountry==="sg" ? (<Billingaddress
                fields={this.state.fieldsbillingaddress}
                onChange={this.fieldAddChange}
                onValid={this.changeaddress}
                onInvalid={() => console.log("Form invalid!")}
              />
              ):(
                <div className="popup-body">
                  <form className="form_sec">
                    <div className="form-group">
                        <div className="focus-out">
                          {this.state.openAddress===true &&
                          <Autocomplete
                            sateValChange={this.sateValChange}
                            mapcountry={mapcountry}
                          />}
                          {/* errMsgPst */}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='focus-out'>
                            <label>Unit Number 1</label>
                            <input type="text" className="form-control input-focus floor_no1" onChange={this.fieldautoAddChange.bind(this, 'floor_no')} />
                        </div>
                    </div>
					          <div className="form-group">
                        <div className='focus-out'>
                            <label>Unit Number 2</label>
                            <input type="text" className="form-control input-focus unit_no1" onChange={this.fieldautoAddChange.bind(this, 'unit_no')} />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="login_pop_sub billing-addrs-sbmt">
                            <button type="button" className="btn btn_black btn_minwid" onClick={this.changeaddress.bind(this)}>Submit</button>
                        </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div id="dvLoading"></div>;
    }
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  var secondaryArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondaryArr = state.secondaryaddress[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    secondaryaddress: secondaryArr,
    staticblack: blacksArr,
    changepassword: state.changepassword,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    addSecondaryAddress: (addPram) => {
      dispatch({ type: ADD_USERSECADDRDATA, addPram });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },    
  };
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
