/* eslint-disable */
import React, { useEffect, useState } from "react";
import {useStripe, useElements, PaymentElement,CardElement} from '@stripe/react-stripe-js';
import cookie from "react-cookies";

import CardSection from './CardSection';
import { apiUrl,appId,baseUrl,stripeReference } from "../Helpers/Config";
import $ from 'jquery'; 
import Axios from 'axios';
import { ReactSession }  from 'react-client-session';

var qs = require('qs');

export default function CheckoutForm() {

  
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);



  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: baseUrl+"placeorder",
      },
    });
    console.log(error);

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
 
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
} 