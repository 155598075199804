import { SET_CARTDATEDATA } from "../actions";

const cartdate = (state = [], action) => {
  switch (action.type) {
    case SET_CARTDATEDATA:
      return [...action.value];
    default:
      return state;
  }
};

export default cartdate;
