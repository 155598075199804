import { takeEvery, call, put } from "redux-saga/effects";
import { GET_AVAILABLE_TIME, SET_AVAILABLE_TIME } from "../actions";
import { apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";
var qs = require("qs");

export const watchGetAvailabileTimeList = function* () {
  yield takeEvery(GET_AVAILABLE_TIME, workerGetAvailabileTimeList);
};

function* workerGetAvailabileTimeList({ avlDateFlg }) {
  try {
    const uri = apiUrlCtrng + "catering/availableTime";
    const result = yield call(Axios.post, uri, qs.stringify(avlDateFlg));
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_AVAILABLE_TIME, value: resultArr });
  } catch {
    console.log("Get Availabile Time Failed");
  }
}
