/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CATERINGPRINT, SET_CATERINGPRINT } from '../actions';
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetCateringPrint = function* () {
  yield takeEvery(GET_CATERINGPRINT, workerGetCateringPrint);
}

function* workerGetCateringPrint({ orderprimaryId }) {
  try {
    const uri = apiUrl + 'cateringorders/orderPdfgenerate?app_id=' + appId + '&order_primary_id=' + orderprimaryId;
	console.log(uri);
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_CATERINGPRINT, value: resultArr });
  } 
  catch {
    console.log('Get Catering order Failed');
  }
} 

