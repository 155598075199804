import { SET_CATERINGCORDER } from '../actions';

const cateringorder = (state = [], action) => {
  switch (action.type) {
    case SET_CATERINGCORDER:
      return [...action.value];
    default: return state;
  }
}

export default cateringorder;
