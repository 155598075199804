import { SET_CATERINPAYMENTDATA } from '../actions';

const cateringpaymentdata = (state = [], action) => {
  switch (action.type) {
    case SET_CATERINPAYMENTDATA:
      return [...action.value];
    default: return state;
  }
}

export default cateringpaymentdata;
