/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl } from "../Helpers/Config";
import { removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
var qs = require("qs");
class Failed extends Component {
  constructor(props) {
    super(props);
    var order_status = '1';
    var urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.get("payment_status") == "failure" &&
      urlParams.get("referenceId") != "" &&
      urlParams.get("referenceId") == cookie.load("ocbcreferenceID")
    ) {
        var order_status = urlParams.get("order_status");
      }else{
        window.location.href = "/";
      }
    this.state = {
      order_status: order_status,
      ocbcreferenceID: cookie.load("ocbcreferenceID")
    }
    setTimeout(function () {
        window.location.href = "/";
    }.bind(this), 15000);
    cookie.remove("ocbcreferenceID", { path: "/" });

  }
  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Order</p>
          </div>
        </div>

        <div className="aboutus-page failed-page">
          <div className="container-one cms-content">
          <div className="common-inner-banner">
            {(this.state.order_status === '1' || this.state.order_status === '0') &&
            <p>Order Failed! Something went wrong if the amount is deducted, it will be refunded soon.<br></br>Ref No: {this.state.ocbcreferenceID} </p>
            }
            {this.state.order_status == '9' &&
            <p>Order Cancelled!.</p>
            }
          </div>
        </div>
        </div>


        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Failed);
