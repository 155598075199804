import { SET_INSTA_FEED } from '../actions';

const instafeed = (state = [], action) => {
  switch (action.type) {
    case SET_INSTA_FEED:
      return [...action.value];
    default: return state;
  }
}

export default instafeed;