import { SET_FAVOURITELIST } from "../actions";

const favouriteproductlist = (state = [], action) => {
  switch (action.type) {
    case SET_FAVOURITELIST:
      return [...action.value];
    default:
      return state;
  }
};

export default favouriteproductlist;
