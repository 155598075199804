/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CARTTIMEDATA, SET_CARTTIMEDATA } from "../actions";
import { apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";
var qs = require("qs");
export const watchGetPackageTime = function* () {
  yield takeEvery(GET_CARTTIMEDATA, workerGetPackageTime);
};

function* workerGetPackageTime({ obj }) {
  try {
    const uri = apiUrlCtrng + "catering/availableTime";
    const result = yield call(Axios.post, uri, qs.stringify(obj));
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CARTTIMEDATA, value: resultArr });
  } catch (e) {
    console.log(e, "Get Time List Failed");
  }
}
