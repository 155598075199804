import { SET_OUTLET_SUBCHARGE } from '../actions';

const outletsubcharge = (state = [], action) => {
  switch (action.type) {
    case SET_OUTLET_SUBCHARGE:
      return [...action.value];
    default: return state;
  }
}

export default outletsubcharge;