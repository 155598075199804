/* eslint-disable */
/* Live */
import cookie from 'react-cookies';

var isSubdomain = function(url) {
    var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
    return !!url.match(regex);
}
console.log(window.location.host)
console.log(isSubdomain(window.location.host))

var isSubdomains = function(url) {
return url.split('.').length > 2;
}
console.log(isSubdomains(window.location.host))

if (!window.location.host.startsWith("www") && !isSubdomain(window.location.host) && !isSubdomains(window.location.host)){
   var hostname = "www." + window.location.hostname;
}else{
   var hostname = window.location.hostname;
}
console.log(hostname)

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlV4 = "https://ccpl.ninjaos.com/apiV4/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/"; 
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.com/media/dev_team/outlet/";

var domainValues = {
  localhost: {
    appId: "5BFBDCE3-B025-4CA2-908E-9D8A91D4F0F4",
    baseUrl: "https://www.crustncrumbs.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/crustncrumbs/no-img-product.png",
    companyname: "Babylon Bakery Pte Ltd",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "crustncrumbs",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 602,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "crustncrumbs",
    mapcountry: "sg",
    bgimage:"crustncrumbs.jpg",
    reservationUrl: ""
  },
  "192.168.218.66": {
    appId: "99F42644-8372-42EA-8BF0-215DF11369E1",
    baseUrl: "http://192.168.218.66:3000/",
    CountryTxt: "Singapore",
    productNoImg: "/img/ninjaent/no-img-product.png",
    companyname: "Ninja Enterprise",
    fbAppId: "284656430443684",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 321,
    currencySybmol: "S$",
    headermenu: "header-menu-sblcpuiewrzotjn1nvqzk53ud",
    foldername: "ninjaent",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "sg",
    bgimage:"",
  },

  "phplus.ninjaossite.com": {
    appId: "E66D5AAF-9077-4885-B379-02D3FCB8BB16",
    baseUrl: "https://phplus.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/ninjaent/no-img-product.png",
    companyname: "Proplus - Demo",
    fbAppId: "2569058459892227",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "PHP",
    currencySybmol: "₱",
    defaultoutlet: 321,
    headermenu: "header-menu-fllvp9k2qcwtbmmnacy51qxsz",
    foldername: "ninjaent",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "ph",
    bgimage:"",
  },

  "ninjaent.ninjaossite.com": {
    appId: "99F42644-8372-42EA-8BF0-215DF11369E1",
    baseUrl: "https://ninjaent.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/ninjaent/no-img-product.png",
    companyname: "Proplus - Demo",
    fbAppId: "2569058459892227",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 321,
    headermenu: "header-menu-fllvp9k2qcwtbmmnacy51qxsz",
    foldername: "ninjaent",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "sg",
    bgimage:"vintage-background.jpg",
  },
  "www.livetoeat.sg-refunded": {
    appId: "11111",
    baseUrl: "https://www.livetoeat.sg-refunded/",
    CountryTxt: "Singapore",
    productNoImg: "/img/livetoeat/no-img-product.png",
    companyname: "demo",
    fbAppId: "682441206143145",
    stripeEmail: "sales@ninjaos.com",
    stripeReference: "livetoeat",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 395,
    headermenu: "header-menu-mila3wy9g1uwontp4mhfvb8yp",
    foldername: "livetoeat",
    mapcountry: "sg",
    bgimage:"",  
  },
  "www.byfleur.sg": {
    appId: "10BA8BBD-0741-4FAF-9579-5ADE67EB379E",
    baseUrl: "https://www.byfleur.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/byfleur/no-img-product.png",
    companyname: "Chicken Delights by Fleur",
    fbAppId: "682441206143145",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "byfleur",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 396,
    headermenu: "header-menu-kysz20i4drplzsu6kebciwx7a",
    foldername: "byfleur",  
    mapcountry: "sg",
    bgimage:"", 
  },
   "www.meatywestern.com": {
    appId: "D1868D6A-47C3-435E-A699-01E4D26A1043",
    baseUrl: "https://www.byfleur.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/meatywestern/no-img-product.png",
    companyname: "Meaty Western",
    fbAppId: "426306786163416",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "meatywestern",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 402,
    headermenu: "header-menu-6l8nktgqburakgjyzi2ss0rhv",
    foldername: "meatywestern",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.lemonsquarebakerytreats.com.ph": {
    appId: "93EDF976-7242-4864-B57E-7B49306521AC",
    baseUrl: "https://www.lemonsquarebakerytreats.com.ph/",
    CountryTxt: "Philippines",
    productNoImg: "/img/lemonsquarebakerytreats/no-img-product.png",
    companyname: "Lemon Square",
    fbAppId: "717090662905425",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lemonsquarebakerytreats",
    stripeDesc: "My Checkout",
    stripeCurrency: "PHP",
    currencySybmol: "₱",
    defaultoutlet: 403,
    headermenu: "header-menu-k7zfp32ywrgovllpzdhesjr8x",
    foldername: "lemonsquarebakerytreats",
    mapcountry: "ph",
    bgimage:"",
  },
  "lemonsquarebakerytreats.com.ph": {
    appId: "93EDF976-7242-4864-B57E-7B49306521AC",
    baseUrl: "https://lemonsquarebakerytreats.com.ph/",
    CountryTxt: "Philippines",
    productNoImg: "/img/lemonsquarebakerytreats/no-img-product.png",
    companyname: "Lemon Square",
    fbAppId: "717090662905425",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lemonsquarebakerytreats",
    stripeDesc: "My Checkout",
    stripeCurrency: "PHP",
    currencySybmol: "₱",
    defaultoutlet: 403,
    headermenu: "header-menu-k7zfp32ywrgovllpzdhesjr8x",
    foldername: "lemonsquarebakerytreats",
    mapcountry: "ph",
    bgimage:"",
  },
   "www.gopalsholycow.com": {
    appId: "B504A24D-6045-489D-B36D-5B2371C8A2E6",
    baseUrl: "https://www.gopalsholycow.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/gopalsholycow/no-img-product.png",
    companyname: "Gopals Holy Cow",
    fbAppId: "298738762464451",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gopalsholycow",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 421,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "gopalsholycow",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.teasocialsg.com": {
    appId: "1D15BD63-9548-4FEC-9701-334D8DDFB5A7",
    baseUrl: "https://www.teasocialsg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/teasocialsg/no-img-product.png",
    companyname: "Tea Social Cafe",
    fbAppId: "1825484181164036",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "teasocialsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 423,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "teasocialsg",
    mapcountry: "sg",
    bgimage:"",
  },
  "beamarry.ninjaossite.com": {
    appId: "DE537AB8-5B80-4690-8FD8-0EC982FC3AC4",
    baseUrl: "https://beamarry.ninjaossite.com/",
    CountryTxt: "Philippines",
    productNoImg: "/img/beamarry/no-img-product.png",
    companyname: "Beamarry New York",
    fbAppId: "111111111111111",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "beamarry",
    stripeDesc: "My Checkout",
    stripeCurrency: "PHP",
    currencySybmol: "₱",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "beamarry",
    mapcountry: "ph",
    bgimage:"",
  },
  "www.roddee.com.sg": {
    appId: "DB18FD87-BE4C-4CB4-9136-77208560D597",
    baseUrl: "https://www.roddee.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/roddee/no-img-product.png",
    companyname: "Rod Dee Thai Cafe",
    fbAppId: "431467785442931",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "roddee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "roddee",
    mapcountry: "sg",
    bgimage:"",
  },
  "roddee.com.sg": {
    appId: "DB18FD87-BE4C-4CB4-9136-77208560D597",
    baseUrl: "https://roddee.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/roddee/no-img-product.png",
    companyname: "Rod Dee Thai Cafe",
    fbAppId: "431467785442931",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "roddee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "roddee",
    mapcountry: "sg",
    bgimage:"",
  },

  "www.germanburgergrill.com": {
    appId: "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2",
    baseUrl: "https://www.germanburgergrill.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/germanburgergrill/no-img-product.png",
    companyname: "GERMAN BURGERGRILL",
    fbAppId: "5187619681344786",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "germanburgergrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "germanburgergrill",
    mapcountry: "sg",
    bgimage:"",
  },
    "www.pita-bakery.com": {
    appId: "14ECCD92-6352-4ED2-B242-8B4C005F7991",
    baseUrl: "https://www.pita-bakery.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/pita-bakery/no-img-product.png",
    companyname: "Pita Bakery",
    fbAppId: "1076659829727057",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pita-bakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "pita-bakery",
    mapcountry: "sg",
    bgimage:"",
  },
   "wholesales.pita-bakery.com": {
    appId: "88636971-37F9-4E2C-B1A9-9B28CAEF36DD",
    baseUrl: "https://wholesales.pita-bakery.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/pita-bakery-wholesale/no-img-product.png",
    companyname: "Pita Bakery",
    fbAppId: "111111111111111",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pita-bakery-wholesale",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "pita-bakery-wholesale",
    mapcountry: "sg",
    bgimage:"",
  },
    "order.jusignatures.com": {
    appId: "E338A8BC-4B4A-427A-A683-60A2F1E173D2",
    baseUrl: "https://order.jusignatures.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/jusignatures/no-img-product.png",
    companyname: "Ju Signatures",
    fbAppId: "443909104215068",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jusignatures",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 437,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "jusignatures",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.thecurryclub.sg": {
    appId: "D9E24470-78C8-4F2F-8587-E27E785F3DC6",
    baseUrl: "https://order.thecurryclub.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/thecurryclub/no-img-product.png",
    companyname: "The Curry Club",
    fbAppId: "577226167137836",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thecurryclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 426,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "thecurryclub",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.thecurryclub.sg": {
    appId: "D9E24470-78C8-4F2F-8587-E27E785F3DC6",
    baseUrl: "https://www.thecurryclub.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/thecurryclub/no-img-product.png",
    companyname: "The Curry Club",
    fbAppId: "577226167137836",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thecurryclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 426,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "thecurryclub",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.makanduluseafoodsg.com": {
    appId: "FE2A13F7-BAEF-49BA-9053-22272EAE5E29",
    baseUrl: "https://www.makanduluseafoodsg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/makanduluseafoodsg/no-img-product.png",
    companyname: "Deyi Restaurant",
    fbAppId: "424050629608090",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "makanduluseafoodsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 436,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "makanduluseafoodsg",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.sycamore-sg.com": {
    appId: "906ADEFF-D8D8-4A61-87E5-59608DB10F5F",
    baseUrl: "https://order.sycamore-sg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sycamore-sg/no-img-product.png",
    companyname: "Sycamore",
    fbAppId: "750427002662355",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sycamore-sg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 439,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sycamore-sg",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.juparocoffee.com": {
    appId: "3C18E255-A450-45E3-B5A6-92156B47B5D5",
    baseUrl: "https://www.juparocoffee.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/juparocoffee/no-img-product.png",
    companyname: "Juparo Coffee",
    fbAppId: "1369638006890865",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "juparocoffee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 440,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "juparocoffee",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.tententhsgourmet.com": {
    appId: "51596F3F-F3C1-4367-A54F-BF6B85E3A1C6",
    baseUrl: "https://www.tententhsgourmet.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/tententhsgourmet/no-img-product.png",
    companyname: "Ten Tenths Gourmet",
    fbAppId: "580025930171714",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tententhsgourmet",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 441,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "tententhsgourmet",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.rbliciouz.com": {
    appId: "9648B00B-467E-4AF0-A03B-014C81945EE5",
    baseUrl: "https://order.rbliciouz.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/rbliciouz/no-img-product.png",
    companyname: "Rbliciouz",
    fbAppId: "748006949775312",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "rbliciouz",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 443,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "rbliciouz",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.lukchang.sg": {
    appId: "49DBBA9C-D537-48A9-B9FA-7726B68632FC",
    baseUrl: "https://www.lukchang.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/lukchang/no-img-product.png",
    companyname: "Luk Chang Thai Cuisine",
    fbAppId: "503364228224501",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lukchang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 446,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "lukchang",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.abumubarakmandirice.com": {
    appId: "FB6F7B50-DEAE-4850-8C54-D9677AF04E0D",
    baseUrl: "https://www.abumubarakmandirice.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/abumubarakmandirice/no-img-product.png",
    companyname: "Abu Mubarak",
    fbAppId: "1147273749335901",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "abumubarakmandirice",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 447,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "abumubarakmandirice",
    mapcountry: "sg",
    bgimage:"",
  },
   "uat.fluffbakery.sg": {
    appId: "BE4C467C-3295-4FFF-AC28-ADCB79D56E8E",
    baseUrl: "https://uat.fluffbakery.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/fluffbakery/no-img-product.png",
    companyname: "Fluff Bakery",
    fbAppId: "589281885983205",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fluffbakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 448,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "fluffbakery",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.fluffbakery.sg": {
    appId: "BE4C467C-3295-4FFF-AC28-ADCB79D56E8E",
    baseUrl: "https://order.fluffbakery.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/fluffbakery/no-img-product.png",
    companyname: "Fluff Bakery",
    fbAppId: "589281885983205",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fluffbakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 448,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "fluffbakery",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.danghongpho.com": {
    appId: "3CE68235-AB68-4E94-8820-ADD4BA22D582",
    baseUrl: "https://www.danghongpho.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/danghongpho/no-img-product.png",
    companyname: "Dang Hong Pho",
    fbAppId: "1409254142897658",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "danghongpho",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 448,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "danghongpho",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.allo.com.sg": {
    appId: "81C63081-6682-4027-B82E-9AA062FB5C42",
    baseUrl: "https://order.allo.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/allo/no-img-product.png",
    companyname: "Allo",
    fbAppId: "492872612838285",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "allo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 451,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "allo",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.yenneysg.com": {
    appId: "1E0CED81-21E8-41CB-8D10-347AA5B7BDDD",
    baseUrl: "https://www.yenneysg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/yenneysg/no-img-product.png",
    companyname: "YenneY",
    fbAppId: "1055126571857395",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yenneysg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 448,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "yenneysg",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.magicthaikitchen.com": {
    appId: "E9F31F91-64D4-46AE-880C-52F729F132A9",
    baseUrl: "https://www.magicthaikitchen.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/magicthaikitchen/no-img-product.png",
    companyname: "Magic Thai Kitchen",
    fbAppId: "1223500311804584",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "magicthaikitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 448,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "magicthaikitchen",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.weekee.com.sg": {
    appId: "56ADC487-11B3-40B1-A393-7BAB4AE5E9A8",
    baseUrl: "https://order.weekee.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/weekee/no-img-product.png",
    companyname: "Wee Kee Catering",
    fbAppId: "440144284751923",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "weekee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 459,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "weekee",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.soonhuatsg.com": {
    appId: "BC16ED35-AB91-437B-8C15-AFEE54359E06",
    baseUrl: "https://www.soonhuatsg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/soonhuatsg/no-img-product.png",
    companyname: "SOON HUAT",
    fbAppId: "1111597486415048",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "soonhuatsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 460,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "soonhuatsg",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.theparteegolf.com": {
    appId: "58E7E880-12B0-4B69-A67B-6682289E4629",
    baseUrl: "https://order.theparteegolf.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/theparteegolf/no-img-product.png",
    companyname: "PAR TEE",
    fbAppId: "395772299299102",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "theparteegolf",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 456,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "theparteegolf",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.dohaven.com": {
    appId: "6A23A2D8-FCE9-431F-B8B0-88CDC59194AB",
    baseUrl: "https://www.dohaven.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/dohaven/no-img-product.png",
    companyname: "DO CAFE",
    fbAppId: "1712952519083473",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dohaven",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 456,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "dohaven",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.myserembanbeefnoodles.com": {
    appId: "1AC978C8-6DFD-4701-A2D1-118953B94C0A",
    baseUrl: "https://www.myserembanbeefnoodles.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/myserembanbeefnoodles/no-img-product.png",
    companyname: "My Seremban Beef Noodles",
    fbAppId: "1246386619446860",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "myserembanbeefnoodles",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 464,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "myserembanbeefnoodles",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.dessertsandbites.com": {
    appId: "9C56E15B-CEE2-4A23-B333-09C458896BC3",
    baseUrl: "https://www.dessertsandbites.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/dessertsandbites/no-img-product.png",
    companyname: "929 desserts and bites",
    fbAppId: "461674226096113",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dessertsandbites",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 465,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "dessertsandbites",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.hatsu.com.sg": {
    appId: "9522101F-63D3-4E33-B728-915D8402851F",
    baseUrl: "https://www.hatsu.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/hatsu/no-img-product.png",
    companyname: "Hatsu",
    fbAppId: "495464715338329",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hatsu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 455,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "hatsu",
    mapcountry: "sg",
    bgimage:"",
  },
  "hatsu.com.sg": {
    appId: "9522101F-63D3-4E33-B728-915D8402851F",
    baseUrl: "https://hatsu.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/hatsu/no-img-product.png",
    companyname: "Hatsu",
    fbAppId: "495464715338329",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hatsu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 455,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "hatsu",
    mapcountry: "sg",
    bgimage:"",
  },
  "order.hatsu.com.sg": {
    appId: "9522101F-63D3-4E33-B728-915D8402851F",
    baseUrl: "https://order.hatsu.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/hatsu/no-img-product.png",
    companyname: "Hatsu",
    fbAppId: "495464715338329",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hatsu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 455,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "hatsu",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.blackcherry.sg": {
    appId: "40DE4037-B264-4990-9B5D-2DE038352D25",
    baseUrl: "https://www.blackcherry.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/blackcherry/no-img-product.png",
    companyname: "Black Cherry",
    fbAppId: "838956577486053",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "blackcherry",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 458,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "blackcherry",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.yevolutionsg.com": {
    appId: "0A12F35E-5D3E-4A6F-892C-F4DD05AF230A",
    baseUrl: "https://www.yevolutionsg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/yevolutionsg/no-img-product.png",
    companyname: "Yevolution",
    fbAppId: "619002453014184",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yevolutionsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 478,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "yevolutionsg",
    mapcountry: "sg",
    bgimage:"",
  },
   "orderonline.nelsonbar.com": {
    appId: "F4AACA00-5595-4C1C-B58B-78B32E28459B",
    baseUrl: "https://orderonline.nelsonbar.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/nelsonbar/no-img-product.png",
    companyname: "Nelson Bar",
    fbAppId: "2148933348619951",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nelsonbar",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 480,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "nelsonbar",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.thekindbowl.com.sg": {
    appId: "CCC7DCF9-3A83-4E81-8C72-BA102569250C",
    baseUrl: "https://www.thekindbowl.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/thekindbowl/no-img-product.png",
    companyname: "The Kind Bowl",
    fbAppId: "613785650478015",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thekindbowl",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 481,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "thekindbowl",
    mapcountry: "sg",
    bgimage:"",
  },
  "thekindbowl.com.sg": {
    appId: "CCC7DCF9-3A83-4E81-8C72-BA102569250C",
    baseUrl: "https://thekindbowl.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/thekindbowl/no-img-product.png",
    companyname: "The Kind Bowl",
    fbAppId: "613785650478015",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thekindbowl",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 481,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "thekindbowl",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.pvj.com.sg": {
    appId: "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380",
    baseUrl: "https://www.pvj.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/parisvanjava/no-img-product.png",
    companyname: "PARIS VAN JAVA",
    fbAppId: "1227656864681703",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "parisvanjava",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 479,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "parisvanjava",
    mapcountry: "sg",
    bgimage:"",
  },
  "pvj.com.sg": {
    appId: "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380",
    baseUrl: "https://pvj.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/parisvanjava/no-img-product.png",
    companyname: "PARIS VAN JAVA",
    fbAppId: "1227656864681703",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "parisvanjava",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 479,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "parisvanjava",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.chulop.com": {
    appId: "5AF0459E-1CD7-45A8-8B49-7202E2A401FC",
    baseUrl: "https://www.chulop.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/chulop/no-img-product.png",
    companyname: "Chulop! By The Syarifs",
    fbAppId: "622215222814979",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chulop",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 479,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "chulop",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.pitahouse.sg": {
    appId: "10B5935B-D3D6-402F-8EE2-81D351779E36",
    baseUrl: "https://www.pitahouse.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/pitahouse/no-img-product.png",
    companyname: "Pita House",
    fbAppId: "1680307382386276",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pitahouse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 483,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "pitahouse",
    mapcountry: "sg",
    bgimage:"",
  },
   "order.unigallery.sg": {
    appId: "93BF1C4A-94C6-4903-947A-FAD62EC4D31B",
    baseUrl: "https://order.unigallery.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/unigallery/no-img-product.png",
    companyname: "Uni Gallery",
    fbAppId: "667862328344491",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "unigallery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 486,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "unigallery",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.fuxiaoxiansg.com": {
    appId: "DF88006C-B62D-4FAB-A905-E3EDFAE72172",
    baseUrl: "https://www.fuxiaoxiansg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/fuxiaoxiansg/no-img-product.png",
    companyname: "Fu Xiao Xian",
    fbAppId: "650248596815506",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fuxiaoxiansg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 486,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "fuxiaoxiansg",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.betsubara.sg": {
    appId: "755D045D-9FF9-473B-AD48-C75D8918CE47",
    baseUrl: "https://www.betsubara.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/betsubara/no-img-product.png",
    companyname: "Betsubara",
    fbAppId: "489902259839166",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "betsubara",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 486,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "betsubara",
    mapcountry: "sg",
    bgimage:"",
  },
   "www.jyugae.sg": {
    appId: "4AACB528-FB3D-4D4A-AC8E-F04226E106BB",
    baseUrl: "https://www.jyugae.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/jyugae/no-img-product.png",
    companyname: "JYU GAE",
    fbAppId: "446727574084933",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jyugae",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 486,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "jyugae",
    mapcountry: "sg",
    bgimage:"",
  },
   "tableqr.collins.sg": {
    appId: "89E3DF42-22D7-483D-AEC0-DC4A6B8D1997",
    baseUrl: "https://tableqr.collins.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/collins/no-img-product.png",
    companyname: "Collins",
    fbAppId: "506332494737640",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "collins",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 495,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "collins",
    mapcountry: "sg",
    bgimage:"",
  },
   "thesapling.shatec.sg": {
    appId: "0811A538-E7CE-4859-82B6-6C9E7BBD9FB5",
    baseUrl: "https://thesapling.shatec.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/thesapling/no-img-product.png",
    companyname: "The Sapling Restaurant",
    fbAppId: "457570216483544",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thesapling",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 486,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "thesapling",
    mapcountry: "sg",
    bgimage:"",
    reservationUrl: "https://www.quandoo.sg/place/the-sapling-64638",
  },
   "www.meowmee.sg": {
    appId: "A56CE4D1-C567-4302-B3BC-0B61F99F9D12",
    baseUrl: "https://www.meowmee.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/meowmee/no-img-product.png",
    companyname: "Meow Mee",
    fbAppId: "545422577170675",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "meowmee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 500,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "meowmee",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.trungnguyencoffeesg.com": {
    appId: "EA50AC65-3CD4-4BE7-AD3E-40B05D88C17F",
    baseUrl: "https://www.trungnguyencoffeesg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/meile/no-img-product.png",
    companyname: "Mei Le",
    fbAppId: "907407533973979",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "meile",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 504,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "meile",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.madurasfood.sg": {
    appId: "99CEC4EE-F97F-4597-ACAC-5741DF5AE9DB",
    baseUrl: "https://www.madurasfood.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/maduras/no-img-product.png",
    companyname: "Maduras Vilas",
    fbAppId: "5631029173633487",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "maduras",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 505,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "maduras",
    mapcountry: "sg",
    bgimage:"",
  },
  "orderonline.juzscooop.co": {
    appId: "E2B51BCF-FBD3-4CC4-B6F3-A201613D7620",
    baseUrl: "https://orderonline.juzscooop.co/",
    CountryTxt: "Singapore",
    productNoImg: "/img/juzscooop/no-img-product.png",
    companyname: "Juz Juz Scooop",
    fbAppId: "1188247192099850",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "juzscooop",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 497,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "juzscooop",
    mapcountry: "sg",
    bgimage:"",
  },
  "order.wangdaebakbbq.sg": {
    appId: "A1CB3315-5AF2-4B06-B2C9-85C9CBC2028F",
    baseUrl: "https://order.wangdaebakbbq.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/wangdaebakbbq/no-img-product.png",
    companyname: "Wang Dae Bak Pocha BBQ",
    fbAppId: "2193664190838756",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wangdaebakbbq",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 509,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "wangdaebakbbq",
    mapcountry: "sg",
    bgimage:"",
  },
  "holland.wangdaebakbbq.sg": {
    appId: "8592A54B-0701-4BF3-AE59-B3713B3C1F2F",
    baseUrl: "https://holland.wangdaebakbbq.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/wangdaebakbbqholland/no-img-product.png",
    companyname: "Wang Dae Bak Korean",
    fbAppId: "489867946608124",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wangdaebakbbqholland",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 510,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "wangdaebakbbqholland",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.breakfasthola.com": {
    appId: "B9C9E349-6215-45DF-ACC0-5A3497353BBC",
    baseUrl: "https://www.breakfasthola.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/breakfasthola/no-img-product.png",
    companyname: "Breakfast Hola",
    fbAppId: "867739100934759",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "breakfasthola",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 512,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "breakfasthola",
    mapcountry: "sg",
    bgimage:"",
  },
  "www.banhmimiss.com": {
    appId: "17465469-AAA3-462C-BD71-EE7C56E34526",
    baseUrl: "https://www.banhmimiss.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/banhmimiss/no-img-product.png",
    companyname: "Banh Mi Thit",
    fbAppId: "2211797802363809",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "banhmimiss",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 514,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "banhmimiss",
    mapcountry: "sg",
    bgimage:"",
  },
   "orderonline.samrang.com.sg": {
    appId: "B9581CF7-6EDF-4474-AEBA-5432FA4EC092",
    baseUrl: "https://orderonline.samrang.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/samrang/no-img-product.png",
    companyname: "Samrang",
    fbAppId: "829752434776453",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "samrang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 511,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "samrang",
    mapcountry: "sg",
    bgimage:"",
  },
  "order.hungrybbq.com": {
    appId: "78CF6EF6-9CD9-4E92-AAD3-E5B7FDBA342C",
    baseUrl: "https://order.hungrybbq.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/hungrybbq/no-img-product.png",
    companyname: "Hungry BBQ",
    fbAppId: "1571710209919904",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hungrybbq",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 513,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "hungrybbq",
    mapcountry: "sg",
    bgimage:"hungry.jpg",
  },
  "www.saigonquansg.com": {
    appId: "8A8DE76F-EF49-46F9-875B-353597094496",
    baseUrl: "https://www.saigonquansg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/saigonquan/no-img-product.png",
    companyname: "Saigon Quan",
    fbAppId: "558086059533110",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "saigonquan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 517,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "saigonquan",
    mapcountry: "sg",
    bgimage:"",
  },
    "www.sultanpalace.com.sg": {
    appId: "CECC6731-C256-4D2F-B27A-83A7B9AA9EF3",
    baseUrl: "https://www.sultanpalace.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sultanpalace/no-img-product.png",
    companyname: "Sultan Palace Turkish & Lebanese",
    fbAppId: "4629986373793308",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sultanpalace",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 515,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sultanpalace",
    mapcountry: "sg",
    bgimage:"sultanpalace.jpg",
  },
    "www.a1indiancurry.com.sg": {
    appId: "DC2D8293-BA0A-48A6-8BEE-328135984C93",
    baseUrl: "https://www.a1indiancurry.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/a1indiancurry/no-img-product.png",
    companyname: "A1 Indian Curry",
    fbAppId: "840390030398495",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "a1indiancurry",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 516,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "a1indiancurry",
    mapcountry: "sg",
    bgimage:"a1indiancurry.jpg",
  },
    "www.olulu.asia": {
    appId: "678F3151-39B7-4152-9817-67A7728DA4B9",
    baseUrl: "https://www.olulu.asia/",
    CountryTxt: "Singapore",
    productNoImg: "/img/olulu/no-img-product.png",
    companyname: "Olulu",
    fbAppId: "681923730077211",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "olulu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 521,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "olulu",
    mapcountry: "sg",
    bgimage:"olulu.jpg",
  },
    "order.masterbriyani.com": {
    appId: "61409044-1FD5-40FE-A1B4-069C1FD814FB",
    baseUrl: "https://order.masterbriyani.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/masterbriyani/no-img-product.png",
    companyname: "Master Briyani",
    fbAppId: "2192047150974190",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "masterbriyani",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 523,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "masterbriyani",
    mapcountry: "sg",
    bgimage:"masterbriyani.jpg",
  },
    "order.sampanman.sg": {
    appId: "E37C6AAC-FDD2-463F-AD88-F40E5D312770",
    baseUrl: "https://order.sampanman.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sampanman/no-img-product.png",
    companyname: "Sampanman",
    fbAppId: "552487160098557",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sampanman",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 524,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sampanman",
    mapcountry: "sg",
    bgimage:"sampanman.jpg",
  },
    "grocery.ninjaossite.com": {
    appId: "A2155048-9289-4322-8B56-2D899CEFEC52",
    baseUrl: "https://grocery.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/grocery/no-img-product.png",
    companyname: "Demo - Grocery",
    fbAppId: "594572435824436",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "grocery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 525,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "grocery",
    mapcountry: "sg",
    bgimage:"grocery.jpg",
  },
    "florist.ninjaossite.com": {
    appId: "C0FFD104-8C5A-4C2E-8F2B-136DE2F85030",
    baseUrl: "https://florist.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/florist/no-img-product.png",
    companyname: "Demo - Florist",
    fbAppId: "1158142154838716",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "florist",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 526,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "florist",
    mapcountry: "sg",
    bgimage:"florist.jpg",
  },
    "retail.ninjaossite.com": {
    appId: "CF441E5D-0C77-4B00-9580-BCBD4EF320C0",
    baseUrl: "https://retail.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/retail/no-img-product.png",
    companyname: "Demo - Retail",
    fbAppId: "724683206031416",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "retail",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 527,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "retail",
    mapcountry: "sg",
    bgimage:"retail.jpg",
  },
    "restaurant.ninjaossite.com": {
    appId: "3B702528-EB90-4F62-B916-0BA46A862D06",
    baseUrl: "https://restaurant.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/restaurant/no-img-product.png",
    companyname: "Demo - Restaurant",
    fbAppId: "739348264155479",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "restaurant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 528,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "restaurant",
    mapcountry: "sg",
    bgimage:"restaurant.jpg",
  },   
    "order.kazoku.com.sg": {
    appId: "F7BFBDB7-DD5F-405B-A9AB-9A17C0BDDBCB",
    baseUrl: "https://order.kazoku.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/kazoku/no-img-product.png",
    companyname: "Kazoku Japanese Cuisine",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kazoku",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 529,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "kazoku",
    mapcountry: "sg",
    bgimage:"retail.jpg",
  },
     "order.farrerhorse.com": {
    appId: "8F525645-06D6-42BA-8274-80929E3A55EA",
    baseUrl: "https://order.farrerhorse.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/farrerhorse/no-img-product.png",
    companyname: "Farrer Horse (Pvt) Ltd",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "farrerhorse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 530,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "farrerhorse",
    mapcountry: "sg",
    bgimage:"farrerhorse.jpg",
  },
    "www.xinglemalahotpot.com": {
    appId: "D2E7E7D0-7F1D-4700-B62C-9FF32D9D104B",
    baseUrl: "https://www.xinglemalahotpot.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/xinglemalahotpot/no-img-product.png",
    companyname: "Xing Le Mala Hotpot",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "xinglemalahotpot",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "xinglemalahotpot",
    mapcountry: "sg",
    bgimage:"xinglemalahotpot.jpg",
  },
    "www.botanict.sg": {
    appId: "3AE7DEFB-EEF1-4AAA-9B19-88A1135BCD90",
    baseUrl: "https://www.botanict.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/botanict/no-img-product.png",
    companyname: "Botanict",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "botanict",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "botanict",
    mapcountry: "sg",
    bgimage:"botanict.jpg",
  },
    "koomi1.ninjaossite.com": {
    appId: "BBC199D4-9E85-465C-82F0-F81C12C6A630",
    baseUrl: "https://koomi1.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/koomi/no-img-product.png",
    companyname: "Koomi Demo",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "koomi1",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "koomi",
    mapcountry: "sg",
    bgimage:"koomi1.jpg",
  },
    "koomi2.ninjaossite.com": {
    appId: "7EBE0942-47C1-431F-9386-80CA7A3A7639",
    baseUrl: "https://koomi2.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/koomi/no-img-product.png",
    companyname: "Koomi Demo",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "koomi2",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "koomi",
    mapcountry: "sg",
    bgimage:"koomi2.jpg",
  },
    "koomi3.ninjaossite.com": {
    appId: "B82767A0-95DE-4F6A-AE8B-C26831EA59CA",
    baseUrl: "https://koomi3.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/koomi/no-img-product.png",
    companyname: "Koomi Demo",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "koomi3",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "koomi",
    mapcountry: "sg",
    bgimage:"koomi3.jpg",
  },
    "koomi4.ninjaossite.com": {
    appId: "0E8CA860-6FB0-4357-9F01-041E39F1ABDE",
    baseUrl: "https://koomi4.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/koomi/no-img-product.png",
    companyname: "Koomi Demo",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "koomi4",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "koomi",
    mapcountry: "sg",
    bgimage:"koomi4.jpg",
  },
    "www.popularfood.sg": {
    appId: "7D593D26-34AD-4E5E-B833-0D7BF864023D",
    baseUrl: "https://www.popularfood.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/popularfood/no-img-product.png",
    companyname: "Popular Food",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "popularfood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "popularfood",
    mapcountry: "sg",
    bgimage:"popularfood.jpg",
  },
    "www.spize.sg": {
    appId: "F60DC85C-6801-4536-8102-65D9A8666940",
    baseUrl: "https://www.spize.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/spize/no-img-product.png",
    companyname: "Spize",
    fbAppId: "955263815791238",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "spize",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "spize",
    mapcountry: "sg",
    bgimage:"spize.jpg",
    reservationUrl: "https://booking.resdiary.com/widget/Standard/SPIZEBEDOK1/1247"
  },
   "www.spizesg.com": {
    appId: "F60DC85C-6801-4536-8102-65D9A8666940",
    baseUrl: "https://www.spizesg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/spize/no-img-product.png",
    companyname: "Spize",
    fbAppId: "955263815791238",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "spize",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "spize",
    mapcountry: "sg",
    bgimage:"spize.jpg",
    reservationUrl: "https://booking.resdiary.com/widget/Standard/SPIZEBEDOK1/1247"
  },
    "srisun.ninjaossite.com": {
    appId: "3F725DE0-AD9B-48BA-A9AE-958A515D6DDE",
    baseUrl: "https://srisun.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/srisun/no-img-product.png",
    companyname: "Srisun Express",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "srisun",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 533,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "srisun",
    mapcountry: "sg",
    bgimage:"srisun.jpg",
  },
    "www.shopatsheraton.com": {
    appId: "C42D48C4-313E-4A6A-9CC3-28A650E83AEE",
    baseUrl: "https://www.shopatsheraton.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sheraton/no-img-product.png",
    companyname: "Sheraton",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sheraton",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sheraton",
    mapcountry: "sg",
    bgimage:"sheraton.jpg",
  },
   "www.shopatsheratonfestive.com": {
    appId: "B3954FD7-174E-423B-B347-4220AF3F2A61",
    baseUrl: "https://www.shopatsheratonfestive.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sheraton/no-img-product.png",
    companyname: "Sheraton Festive",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sheraton",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sheraton",
    mapcountry: "sg",
    bgimage:"sheraton.jpg",
  },
   "www.potmaster.com.sg": {
    appId: "AA6D9602-82B0-4DBC-8A37-BC5E9BDED3F4",
    baseUrl: "https://www.potmaster.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/potmaster/no-img-product.png",
    companyname: "Pot Master",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "potmaster",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "potmaster",
    mapcountry: "sg",
    bgimage:"potmaster.jpg",
  },
   "abundance.ninjaossite.com": {
    appId: "D68B5E90-6055-4E89-A708-3537A01A4E1D",
    baseUrl: "https://abundance.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/abundance/no-img-product.png",
    companyname: "Abundance",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "abundance",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "abundance",
    mapcountry: "sg",
    bgimage:"abundance.jpg",
  },
   "maixiang.ninjaossite.com": {
    appId: "F4D63D29-E48A-4511-8F9C-05D5FFDA2260",
    baseUrl: "https://maixiang.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/maixiang/no-img-product.png",
    companyname: "MaiXiang",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "maixiang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "maixiang",
    mapcountry: "sg",
    bgimage:"maixiang.jpg",
  },
     "www.nowafter.com.sg": {
    appId: "667AE4A4-A612-44BF-983A-5F159FE6D3FB",
    baseUrl: "https://www.nowafter.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/nowafter/no-img-product.png",
    companyname: "MaiXiang",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nowafter",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "nowafter",
    mapcountry: "sg",
    bgimage:"nowafter.jpg",
  },
   "uat.carraracafesg.com": {
    appId: "A59A5658-E4BE-4287-A5AD-5E2CF3B0E71A",
    baseUrl: "https://uat.carraracafesg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/carrara/no-img-product.png",
    companyname: "Carrara",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "carrara",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "carrara",
    mapcountry: "sg",
    bgimage:"carrara.jpg",
  },
   "order.carraracafesg.com": {
    appId: "A59A5658-E4BE-4287-A5AD-5E2CF3B0E71A",
    baseUrl: "https://order.carraracafesg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/carrara/no-img-product.png",
    companyname: "Carrara",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "carrara",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "carrara",
    mapcountry: "sg",
    bgimage:"carrara.jpg",
  },
   "timhowan.ninjaossite.com": {
    appId: "E3CAF2B9-1A93-42B9-BDA2-907F0DFA9E6C",
    baseUrl: "https://timhowan.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/timhowan/no-img-product.png",
    companyname: "Tim Ho Wan",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "timhowan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "timhowan",
    mapcountry: "sg",
    bgimage:"timhowan.jpg",
  },
   "bigfishsmallfish.ninjaossite.com": {
    appId: "9063DAD1-35AE-434A-98C9-0A8A66F48503",
    baseUrl: "https://bigfishsmallfish.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/bigfishsmallfish/no-img-product.png",
    companyname: "Big Fish Small Fish",
    fbAppId: "866235847785397",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bigfishsmallfish",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 551,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "bigfishsmallfish",
    mapcountry: "sg",
    bgimage:"bigfishsmallfish.jpg",
  },
  "tamjaisamgor.ninjaossite.com": {
    appId: "547C5292-89F8-44F3-887A-C4858102DD4C",
    baseUrl: "https://tamjaisamgor.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/tamjaisamgor/no-img-product.png",
    companyname: "TamJai Samgor Mixian",
    fbAppId: "866235847785397",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "tamjaisamgor",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 567,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "tamjaisamgor",
    mapcountry: "sg",
    bgimage:"tamjaisamgor.jpg",
  },
  "carnival.ninjaossite.com": {
    appId: "D93083AF-FD2F-4037-AD20-44BD976DC5E0",
    baseUrl: "https://carnival.ninjaossite.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/carnival/no-img-product.png",
    companyname: "Carnival",
    fbAppId: "866235847785397",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "carnival",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 568,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "carnival",
    mapcountry: "sg",
    bgimage:"carnival.jpg",
  },
  "www.lasaigoncafe.com": {
    appId: "B3B1367F-4AFF-4C37-9801-8310EE7C1EC7",
    baseUrl: "https://www.lasaigoncafe.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/lasaigoncafe/no-img-product.png",
    companyname: "La Saigon",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "lasaigoncafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 568,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "lasaigoncafe",
    mapcountry: "sg",
    bgimage:"lasaigoncafe.jpg",
  },
  "www.sakurasiancuisine.com": {
    appId: "FE7566C7-F7E5-4626-97DA-85942AC41EB7",
    baseUrl: "https://www.sakurasiancuisine.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sakurasiancuisine/no-img-product.png",
    companyname: "Sakura Thai",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "sakurasiancuisine",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 571,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sakurasiancuisine",
    mapcountry: "sg",
    bgimage:"sakurasiancuisine.jpg",
  },
  "www.chengjiseafood.com": {
    appId: "5D6E52E2-A48B-4C88-993F-53FA44592436",
    baseUrl: "https://www.chengjiseafood.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/chengjiseafood/no-img-product.png",
    companyname: "Cheng Ji Seafood",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "chengjiseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 571,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "chengjiseafood",
    mapcountry: "sg",
    bgimage:"chengjiseafood.jpg",
  },
  "www.sumac.sg": {
    appId: "06E41E85-7866-4732-AED2-75D132AD196D",
    baseUrl: "https://www.sumac.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/sumacsg/no-img-product.png",
    companyname: "Sumac",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "sumacsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 581,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "sumacsg",
    mapcountry: "sg",
    bgimage:"sumacsg.jpg",
    reservationUrl: "https://booking.resdiary.com/widget/Standard/SumacRifleRange/723"
  },
  "www.bomsnbuns.com": {
    appId: "E7BDA6C4-4385-4D17-A398-2474140F26CE",
    baseUrl: "https://www.bomsnbuns.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/bomsnbuns/no-img-product.png",
    companyname: "Boms & Buns",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "bomsnbuns",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 585,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "bomsnbuns",
    mapcountry: "sg",
    bgimage:"bomsnbuns.jpg",
    reservationUrl: ""
  },
  "www.mimifasosg.com": {
    appId: "AF4F18BC-521C-4F02-999C-7CF386CAB57F",
    baseUrl: "https://www.mimifasosg.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/mimifasosg/no-img-product.png",
    companyname: "MIMIFASO",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "mimifasosg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 585,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "mimifasosg",
    mapcountry: "sg",
    bgimage:"mimifasosg.jpg",
    reservationUrl: ""
  },
  "www.pinxiangkitchen.sg": {
    appId: "5E404448-06FD-4A8D-87D9-65388A3544AD",
    baseUrl: "https://www.pinxiangkitchen.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/pinxiangkitchen/no-img-product.png",
    companyname: "Pin Xiang",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "pinxiangkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 585,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "pinxiangkitchen",
    mapcountry: "sg",
    bgimage:"pinxiangkitchen.jpg",
    reservationUrl: ""
  },
  "www.saltxhebrews.com": {
    appId: "7A5B82BF-F806-45A6-9F03-17045CD82DFE",
    baseUrl: "https://www.saltxhebrews.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/saltxhebrews/no-img-product.png",
    companyname: "Never Loses Its Flavour",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "saltxhebrews",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 588,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "saltxhebrews",
    mapcountry: "sg",
    bgimage:"saltxhebrews.jpg",
    reservationUrl: ""
  },
  "order-for-delivery-ohs.millenniumhotels.com": {
    appId: "C1233084-8F9C-40F3-A8EA-854353770C15",
    baseUrl: "https://order-for-delivery-ohs.millenniumhotels.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/orchardhotel/no-img-product.png",
    companyname: "Orchard Hotel - Singapore",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "orchardhotel",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 586,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "orchardhotel",
    mapcountry: "sg",
    bgimage:"orchardhotel.jpg",
    reservationUrl: ""
  },
  "order.dukebakery.sg": {
    appId: "7BA2D8BC-959F-4C6F-B5EB-439A92897E3C",
    baseUrl: "https://order.dukebakery.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/dukebakery/no-img-product.png",
    companyname: "Duke Bakery",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "dukebakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 595,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "dukebakery",
    mapcountry: "sg",
    bgimage:"dukebakery.jpg",
    reservationUrl: ""
  },
  "uat.saffrons.com.sg": {
    appId: "21FE4589-1E20-480C-AF1B-ED3DAA4A8C16",
    baseUrl: "https://uat.saffrons.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/saffrons/no-img-product.png",
    companyname: "Saffrons @1163",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "saffrons",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 595,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "saffrons",
    mapcountry: "sg",
    bgimage:"saffrons.jpg",
    reservationUrl: ""
  },
  "uat.epikebabs.com": {
    appId: "3CC8526B-8398-4B49-AF2A-0B829216DC90",
    baseUrl: "https://uat.epikebabs.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/epikebabs/no-img-product.png",
    companyname: "EPIKebabs",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "epikebabs",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 598,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "epikebabs",
    mapcountry: "sg",
    bgimage:"epikebabs.jpg",
    reservationUrl: ""
  },
  "www.crustncrumbs.sg": {
    appId: "5BFBDCE3-B025-4CA2-908E-9D8A91D4F0F4",
    baseUrl: "https://www.crustncrumbs.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/crustncrumbs/no-img-product.png",
    companyname: "Babylon Bakery Pte Ltd",
    fbAppId: "2473358092914159",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "crustncrumbs",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 602,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "crustncrumbs",
    mapcountry: "sg",
    bgimage:"crustncrumbs.jpg",
    reservationUrl: ""
  },
  "amburbriyani.com": {
      appId: "A6EEAFE7-4CB3-49E5-940B-4A0F46BEE4E2",
      baseUrl: "https://amburbriyani.com/",
      CountryTxt: "Singapore",
      productNoImg: "/img/amburbriyani/no-img-product.png",
      companyname: "Ambur Briyani Restaurant & Catering pte ltd",
      fbAppId: "2473358092914159",
      stripeEmail: "dev@jankosoft.com",
      stripeReference: "amburbriyani",
      stripeDesc: "My Checkout",
      stripeCurrency: "SGD",
      currencySybmol: "S$",
      defaultoutlet: 603,
      headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
      foldername: "amburbriyani",
      mapcountry: "sg",
      bgimage:"amburbriyani.jpg",
      reservationUrl: ""
    },
    "ngonbaguette.ninjaossite.com": {
        appId: "120496F4-DF7B-41A4-8816-7F04C575800B",
        baseUrl: "https://ngonbaguette.ninjaossite.com/",
        CountryTxt: "Singapore",
        productNoImg: "/img/ngonbaguette/no-img-product.png",
        companyname: "Ngon Baguette pte ltd",
        fbAppId: "2473358092914159",
        stripeEmail: "dev@jankosoft.com",
        stripeReference: "ngonbaguette",
        stripeDesc: "My Checkout",
        stripeCurrency: "SGD",
        currencySybmol: "S$",
        defaultoutlet: 604,
        headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
        foldername: "ngonbaguette",
        mapcountry: "sg",
        bgimage:"ngonbaguette.jpg",
        reservationUrl: ""
      },
      "tianle.ninjaossite.com": {
          appId: "5F7E83EE-672E-48B0-9D8B-F1A3AEB1876D",
          baseUrl: "https://tianle.ninjaossite.com/",
          CountryTxt: "Singapore",
          productNoImg: "/img/tianle/no-img-product.png",
          companyname: "Tian le",
          fbAppId: "2473358092914159",
          stripeEmail: "dev@jankosoft.com",
          stripeReference: "tianle",
          stripeDesc: "My Checkout",
          stripeCurrency: "SGD",
          currencySybmol: "S$",
          defaultoutlet: 605,
          headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
          foldername: "tianle",
          mapcountry: "sg",
          bgimage:"tianle.jpg",
          reservationUrl: ""
        },
};


console.log(domainValues, hostname, 'hostname')

if(!domainValues[hostname]){
  hostname = hostname.replace('www.','');
}

export const appId = domainValues[hostname].appId;
export const stripeImage =
  "img/" + domainValues[hostname].foldername + "/stripe_logo.png";
export const productNoImg = domainValues[hostname].productNoImg;
export const companyname = domainValues[hostname].companyname;
export const fbAppId = domainValues[hostname].fbAppId;
export const stripeEmail = domainValues[hostname].stripeEmail;
export const stripeReference = domainValues[hostname].stripeReference;
export const stripeDesc = domainValues[hostname].stripeDesc;

export const defaultoutlet = domainValues[hostname].defaultoutlet;
export const headermenu = domainValues[hostname].headermenu;
export const instaToken = domainValues[hostname].instaToken;
export const foldername = domainValues[hostname].foldername;
export const mainLogo =
  "/img/" + domainValues[hostname].foldername + "/logo.png";
export const noimage =
  "/img/" + domainValues[hostname].foldername + "/no-img-product.png";
export const productImg =
  "/img/" + domainValues[hostname].foldername + "/noimg-400x400.png";
export const noimage470 =
  "/img/" + domainValues[hostname].foldername + "/noimg-470x240.jpg";
export const baseUrl = domainValues[hostname].baseUrl;


export const stripeCurrency     = (typeof (cookie.load("clientCurrencyName"))!="undefined"?cookie.load("clientCurrencyName"):domainValues[hostname].stripeCurrency);
export const currencySybmol = (typeof (cookie.load("clientCurrency"))!="undefined"?cookie.load("clientCurrency"):domainValues[hostname].currencySybmol);
export const CountryTxt = (typeof (cookie.load("clientCountry"))!="undefined"?cookie.load("clientCountry"):domainValues[hostname].CountryTxt);
export const mapcountry = domainValues[hostname].mapcountry;
export const bgimage = domainValues[hostname].bgimage;
export const reservationUrl = domainValues[hostname].reservationUrl;