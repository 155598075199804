import { SET_CATERINGPRINT } from '../actions';

const cateringprint = (state = [], action) => {
  switch (action.type) {
    case SET_CATERINGPRINT:
      return [...action.value];
    default: return state;
  }
}

export default cateringprint;
