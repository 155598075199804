import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_RES_AVAILABLE_DATE, SET_RES_AVAILABLE_DATE } from '../actions';
import { apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
var qs = require('qs');

export const watchGetResAvailabileDateList = function* () {
  yield takeEvery(GET_RES_AVAILABLE_DATE, workerGetResAvailabileDateList);
}

function* workerGetResAvailabileDateList({ avlDateFlg }) {
  try {
		var postObject = {};
		postObject = { 'app_id': avlDateFlg['app_id'], 'outlet_id': avlDateFlg['outlet_id'], 'flag_date': avlDateFlg['flag_date'], 'flag_date_post': avlDateFlg['flag_date_post'], 'flag_date_pre': avlDateFlg['flag_date_pre'],'flag_key':avlDateFlg['flag_key']};
	
		const uri = apiUrl+'reservation1/reservation_availabilitydate_with_closeddate1';
		const result = yield call(Axios.post, uri, qs.stringify(postObject));	
		var resultArr = [];
		resultArr.push(result.data);
		yield put({ type: SET_RES_AVAILABLE_DATE, value: resultArr });
  } 
  catch {
    console.log('Get Availabile Date Failed');
  }
}
