/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import iconcategory from "../../common/images/icon-category.png";
import icondarkcategory from "../../common/images/icon-category-dark.png";

class MenuDNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
    };
  }

  goCategoryProducts(selectedNavigation) {
    $(document).find('.closeProductDetails').trigger('click');
    this.setState({ selectedNavigation: selectedNavigation });
      setTimeout(function(){
        if($(document).find("#pro_cat_" + selectedNavigation).length > 0){
            $("html, body").animate(
              {
                scrollTop: $(document).find("#pro_cat_" + selectedNavigation).offset().top - 180,
              },
              1000
            );
            $(document).find('body').click();
        }
      },600)
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }


  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    let navigateCommon = this.props.navigateCommon;

    var selectedNav = this.props.productState.selectedNavigation;
    var selectedNavigationFilter =
      this.props.productState.selectedNavigationFilter;
    $(".productpage-main-div").removeClass("pantry-work-in-progress");
    $(
      ".product-cconmid-lhs, .product-cms-content-bottom, .product-menu-listing"
    ).show();
    var selectedMainCatId = this.props.productState.selectedMainCatId;
    var totalNav = 0;
    var mainMenu = "";
    if (Object.keys(navigateMenuArr).length > 0) {
      mainMenu = navigateMenuArr.map((loaddata, index) => {
        totalNav++;
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNavigationFilter !== ""
                  ? selectedNavigationFilter === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                  : selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                id={"menuNav_" + loaddata.pro_cate_slug}
                onClick={(e) => {
                  this.goCategoryProducts(loaddata.pro_cate_slug);
                }}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                <div>
                  {loaddata.pro_cate_image !== "" &&
                  loaddata.pro_cate_image !== null ? (
                    <img
                      src={
                        navigateCommon.category_image_url +
                        "/" +
                        loaddata.pro_cate_image
                      }
                      alt={loaddata.menu_custom_title}
                      className="menu-list-normal"
                    />
                  ) : (
                    <img
                      src={iconcategory}
                      alt={loaddata.menu_custom_title}
                      className="menu-list-normal"
                    />
                  )}
                </div>

                {stripslashes(loaddata.menu_custom_title)}
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                onClick={(e) => {
                  this.goCategoryProducts(loaddata.pro_subcate_slug);
                }}
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title)}
              </Link>
            </div>
          );
        }
      });
      if (totalNav === 0 && selectedMainCatId !== "") {
        $(
          ".product-cconmid-lhs, .product-cms-content-bottom, .product-menu-listing"
        ).hide();
        $(".productpage-main-div").addClass("pantry-work-in-progress");
        mainMenu = (
          <div className="pantry-comming-soon">
            Work in Progress.....
            <br />
            Coming Soon !!
          </div>
        );
      }
      setTimeout(function () {
        if ($(".owl-stage-outer .owl-stage .owl-item .owl-stage").length > 0) {
          $(".owl-stage-outer .owl-stage .owl-item .owl-stage").each(
            function () {
              $(this).parents(".owl-item").hide();
            }
          );
        }
      }, 100);
      return mainMenu;
    } else {
      return;
    }
  }
  render() {
    var settingsTestimonial = {
      items: 6,
      autoplay: false,
      margin: 22,
      responsive: {
        0: {
          items: 3,
        },
        500: {
          items: 4,
        },
        900: {
          items: 5,
        },
        1200: {
          items: 7,
        },
      },
    };
    return (
      <>
        {Object.keys(this.props.navigateMenu).length > 0 && (
          <OwlCarousel options={settingsTestimonial}>
            {this.navigateMenuList()}
          </OwlCarousel>
        )}
      </>
    );
  }
}

export default MenuDNavigation;
