/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_EVENTS, SET_EVENTS, GET_EVENTDATA, SET_EVENTDATA } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetEvents = function* () {
  yield takeEvery(GET_EVENTS, workerGetEvents);
}

function* workerGetEvents() {
  try {
    const uri = apiUrl+'cms/whats_happening?app_id='+appId;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_EVENTS, value: resultArr });
  } 
  catch {
    console.log('Fetching Events Failed');
  }
} 

export const watchGetEventData = function* () {
    yield takeEvery(GET_EVENTDATA, workerGetEventData);
  }
  
  function* workerGetEventData({ postObject }) {
    try {
       const result = yield call(getEventData, postObject);
      var resultArr = [];
      resultArr.push(result.data);
      yield put({ type: SET_EVENTDATA, value: resultArr });
    } 
    catch {
      console.log('updated failed');
    }
  } 
  
  
  function getEventData(postObject) {
    return  Axios.post(apiUrl + 'events/eventCreate',postObject)
  } 

