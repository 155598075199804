import { SET_CATERINGHISTORY } from '../actions';

const cateringhistory = (state = [], action) => {
  switch (action.type) {
    case SET_CATERINGHISTORY:
      return [...action.value];
    default: return state;
  }
}

export default cateringhistory;
