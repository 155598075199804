import { SET_MAINCATEGORIES } from "../actions";

const maincategories = (state = [], action) => {
  switch (action.type) {
    case SET_MAINCATEGORIES:
      return [...action.value];
    default:
      return state;
  }
};

export default maincategories;
