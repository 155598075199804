import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_RES_AVAILABLE_TIME, SET_RES_AVAILABLE_TIME } from '../actions';
import { apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
var qs = require('qs');

export const watchGetResAvailabileTimeList = function* () {
  yield takeEvery(GET_RES_AVAILABLE_TIME, workerGetResAvailabileTimeList);
}

function* workerGetResAvailabileTimeList({ avlTimeFlg }) { 
  try {
		var postObject = {};
		postObject = { 'app_id': avlTimeFlg['app_id'], 'outlet_id': avlTimeFlg['outlet_id'], 'availability_date': avlTimeFlg['availability_date'], 'flag_time': avlTimeFlg['flag_time'], 'flag_key': avlTimeFlg['flag_key'],'flag_time_pre':avlTimeFlg['flag_time_pre']};
	
		const uri = apiUrl+'reservation1/reservation_availability_times_new1';
		const result = yield call(Axios.post, uri, qs.stringify(postObject));	
		var resultArr = [];
		resultArr.push(result.data);
		yield put({ type: SET_RES_AVAILABLE_TIME, value: resultArr });
  } 
  catch {
    console.log('Get Availabile Date Failed');
  }
}
