/* eslint-disable */
import React from "react";
import ReactTooltip from "react-tooltip";
import cookie from "react-cookies";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import warning from "../../common/images/warning.svg";
import hiHndImage from "../../common/images/hi.png";
import crossImg from "../../common/images/cross1.png";
import warningone from "../../common/images/warning-block.svg";
import delete_white from "../../common/images/delete-white.png";
import moment from "moment";

import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  getCateringCalculatedAmount,
  getGstValue,
  getSurchargesplitup,
  showPriceValueNew,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrlCtrng,
  cateringId,
  apiUrl,
  CountryTxt,
  apiUrlV2,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  DESTROY_CART_DETAIL,
  GET_STATIC_BLOCK,
  GET_CARTDATEDATA,
  GET_CARTTIMEDATA,
  GET_ACTIVITYCOUNT,
  DELETE_CART_DETAIL,
  GET_DELETE_CATERING_CART,
} from "../../actions";

var Parser = require("html-react-parser");
var qs = require("qs");
var genderHtml =
  "<option value=''> Your Gender </option><option value='M'> Male </option><option value='F'> Female </option><option value='O'> Unspecified </option>";
var genderDropDown = Parser(genderHtml);
class Checkout extends React.Component {
  constructor(props) {
    super(props);
    var zone_id = cookie.load("catering_zone_id")
      ? cookie.load("catering_zone_id")
      : "";
    var outlet_id = cookie.load("outlet_id")
      ? cookie.load("outlet_id")
      : cookie.load("orderOutletId");
    this.state = {
      userBirthdate:
        cookie.load("UserBirthdate") != "" &&
        cookie.load("UserBirthdate") != undefined
          ? cookie.load("UserBirthdate")
          : "",
      delivery_add_postalcode: cookie.load("catering_postal_code")
        ? cookie.load("catering_postal_code")
        : "",
      delivery_add_addressline: cookie.load("catering_address_line")
        ? cookie.load("catering_address_line")
        : "",
      delivery_add_floorno: cookie.load("catering_floor_no")
        ? cookie.load("catering_floor_no")
        : "",
      delivery_add_unitno: cookie.load("catering_unit_no")
        ? cookie.load("catering_unit_no")
        : "",
      same_as_del_address: false,
      billing_add_postalcode: "",
      billing_add_addressline: "",
      billing_add_floor_no: "",
      billing_add_unitno: "",
      zone_id: zone_id,
      outlet_id: outlet_id,
      orderDate:
        cookie.load("order_date") !== "" &&
        typeof cookie.load("order_date") !== undefined &&
        typeof cookie.load("order_date") !== "undefined"
          ? cookie.load("order_date")
          : "",
      dayList: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      orderdate_error: "",
      zoneDetails: [],
      surcharge: 0,
      surchargeLog: "",
      settings: [],
      start_time: "",
      end_time: "",
      cart_items: [],
      disableSlot: [],
      cart_zone_delivery_charge: 0,
      spl_instruction: "",
      terms: false,
      delivery_blocks: "",
      start_validate: false,
      end_validate: false,
      cartAvailbility: "",
    };
  }

  componentDidMount() {
    this.getCommonSetting();
    if (
      typeof cookie.load("outlet_id") !== "undefined" ||
      cookie.load("outlet_id") !== ""
    ) {
      this.props.getCartdatedata({
        dateFlagkey: "START",
        outletId: cookie.load("outlet_id"),
      });
    }
  }

  componentWillMount() {}
  componentDidUpdate() {
    if (!cookie.load("UserId")) {
      cookie.save("openLogin", "yes", { path: "/" });
      const { history } = this.props;
      history.push("/");
    }
  }
  componentWillReceiveProps(nextProps) {
    /*Get date list*/
    if (
      this.props.cartdate !== nextProps.cartdate &&
      typeof nextProps.cartdate !== "undefined"
    ) {
      if (nextProps.cartdate[0].status === "success") {
        let dateArrList = [];
        let commonArrLt = [];
        let timeslotArrLt = [];
        let interval_time = 15;
        let mindate = new Date();
        let maxdate = "";

        dateArrList = nextProps.cartdate[0].result_set;
        commonArrLt = nextProps.cartdate[0].common_data;
        timeslotArrLt = nextProps.cartdate[0].timeslot_data;
        interval_time = nextProps.cartdate[0].interval_time;

        let data_max = Object.keys(dateArrList).length;
        let ind = parseInt(data_max) - parseInt(1);
        maxdate = new Date(nextProps.cartdate[0].result_set[ind].fulldate);
        this.setState({ available_datelist: dateArrList });
        this.setState({ common_datalist: commonArrLt });
        this.setState({ time_slotlist: timeslotArrLt });
        this.setState({ interval_time_sat: interval_time });
        var catergDate = [];
        var disable_dates = [];
        if (dateArrList.length > 0) {
          dateArrList.map((date, index) => {
            if (date.isclose === "No") {
              catergDate.push(new Date(date.fulldate));
              return false;
            } else {
              disable_dates.push(new Date(date.fulldate));
              return false;
            }
          });
        }
        mindate = new Date(catergDate[0]);
        var errorHtml = "";
        if (Object.keys(dateArrList).length > 0) {
          this.setState({ date_errorHtml: "" });
        } else {
          this.setState({ date_errorHtml: errorHtml });
        }
        this.setState(
          {
            catTimeLoading: false,
            catering_date: catergDate,
            disable_dates: disable_dates,
            mindate: mindate,
            maxdate: maxdate,
          },
          function () {
            this.loadCartDet();
          }
        );
      }
    }
    /* Delete Cart Item */
    if (
      nextProps.deleteDetail &&
      this.props.deleteDetail !== nextProps.deleteDetail
    ) {
      if (nextProps.deleteDetail.length > 0) {
        hideLoader("catering_cart_list", "class");
        if (nextProps.deleteDetail[0].status === "ok") {
          swal("Success!", nextProps.deleteDetail[0].message, "success");
          this.loadCartDet();
        } else {
          swal("Error!", nextProps.deleteDetail[0].message, "error");
        }
      }
    }
    /* Static Block */
    if (this.state.staticblockData !== nextProps.staticblockData) {
      if (
        nextProps.staticblockData !== "" &&
        typeof nextProps.staticblockData !== undefined &&
        typeof nextProps.staticblockData !== "undefined"
      ) {
        this.setState({ staticblockData: nextProps.staticblockData });
        if (Object.keys(nextProps.staticblockData).length > 0) {
          nextProps.staticblockData.result_set.map((data) => {
            if (data.staticblocks_slug === "delivery-charge-calculation") {
              var delivery_block = data.staticblocks_description;
              this.setState({ delivery_blocks: delivery_block });
            }
          });
        }
      }
    }
  }

  showSuccess() {
    if (this.state.start_validate && this.state.end_validate) {
      var startTime = this.state.event_start;
      var endTime = this.state.event_end;
      if (startTime > endTime || startTime === endTime) {
        swal("Error!", "Invalid start & end time!", "error");
        $("#event_start_timer").prop("selectedIndex", "");
        $("#event_end_timer").prop("selectedIndex", "");
        this.setState({ start_validate: false, end_validate: false });
      } else {
        swal(
          "Success!",
          "All Set! Please click Order Now to continue.",
          "success"
        );
      }
    }
  }

  handleTextChange(e) {
    var value = e.target.value;
    var name = e.target.name;
    if (name === "delivery_add_floorno") {
      cookie.save("catering_floor_no", value, { path: "/" });
    } else if (name === "delivery_add_unitno") {
      cookie.save("catering_unit_no", value, { path: "/" });
    } else if (e.target.name === "billing_add_postalcode") {
      if (e.target.value.length !== "" && e.target.value.length >= 6) {
        $(".billing_add_postalcode_error").hide();
        //var urlShringTxt =  "https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal=" + e.target.value;

        // apiUrlV2 +
        // "outlets/findOutletZone?app_id=" +
        // appId +
        // "&availability_id=" +
        // cateringId +
        // "&postal_code=" +
        // e.target.value +
        // "&postalcode_basedoutlet=yes"

        var urlShringTxt =
          "https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal=" +
          e.target.value;

        axios.get(urlShringTxt).then((res) => {
          var custAddress = "";
          // if (res.data.status === "ok") {
          //   var outltResulSet = res.data.result_set;
          //   var outletAddress = "";
          //   if (Object.keys(outltResulSet.postal_code_information).length > 0) {
          //     var outletAddress =
          //       outltResulSet.postal_code_information.zip_buno !== ""
          //         ? outltResulSet.postal_code_information.zip_buno +
          //           ", " +
          //           outltResulSet.postal_code_information.zip_sname
          //         : outltResulSet.postal_code_information.zip_sname;
          //     custAddress = outletAddress + " " + CountryTxt;
          //   }
          //   this.setState(
          //     { billing_add_addressline: custAddress },
          //     function () {
          //       $(".billing_add_addressline_error").hide();
          //     }
          //   );
          // } else {
          //   this.setState({ billing_add_addressline: "" });
          // }

          if (Object.keys(res.data.results).length > 0) {
            var outltResulSet = res.data.results[0];
            if (Object.keys(outltResulSet).length > 0) {
              var outletAddress = outltResulSet.ROAD_NAME;
              custAddress = outletAddress + " " + CountryTxt;
            }

            this.setState(
              { billing_add_addressline: custAddress },
              function () {
                $(".billing_add_addressline_error").hide();
              }
            );

            return false;
          } else {
            this.setState({ billing_add_addressline: "" });
            swal("Error!", "Sorry! Invalid Postal Code.", "success");
          }
        });
      }
      this.setState({ billing_add_postalcode: e.target.value }, function () {
        $(".billing_add_postalcode_error").hide();
      });
    }
    this.setState({ [name]: value });
  }
  handleCheckboxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }
  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  handleChange = (date) => {
    if (date === null) {
      date = "";
      cookie.save("order_date", "", { path: "/" });
      this.setState({ orderDate: date, orderdate_error: 1 });
    } else {
      cookie.save("order_date", moment(date).format("MM/DD/YYYY"), {
        path: "/",
      });
      this.setState({ orderDate: date, orderdate_error: "" }, function () {
        this.loadCartDet();
      });
    }
  };
  loadCartDet() {
    var customerParam = "";
    if (typeof cookie.load("UserId") === "undefined") {
      customerParam = "&reference_id=" + getReferenceID();
    } else {
      customerParam = "&customer_id=" + cookie.load("UserId");
    }
    if (this.state.zone_id !== "") {
      customerParam += "&zone_id=" + this.state.zone_id;
    }
    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        let cartDetailsMain = "";
        let cartItems = [];
        let cartcount = 0;
        let cart_zone_delivery_charge = 0;
        if (res.data.status === "ok") {
          cartDetailsMain = res.data.result_set.cart_details;
          cartItems = res.data.result_set.cart_items;
          cartcount = res.data.result_set.cart_details.cart_total_items;
          if (
            cartDetailsMain.cart_zone_delivery_charge !== "" &&
            typeof cartDetailsMain.cart_zone_delivery_charge !== "undefined" &&
            typeof cartDetailsMain.cart_zone_delivery_charge !== undefined
          ) {
            cart_zone_delivery_charge =
              cartDetailsMain.cart_zone_delivery_charge;
          }
        } else {
          this.props.history.push("/");
        }
        this.setState(
          {
            cartDetailsMain: cartDetailsMain,
            cartItems: cartItems,
            cartcount: cartcount,
            cart_zone_delivery_charge: cart_zone_delivery_charge,
          },
          function () {
            this.getZoneDetails();
            this.getSurCharge();
            this.getCartDetList();
          }
        );
      });
  }
  getZoneDetails() {
    if (this.state.zone_id !== "") {
      var urlShringTxt =
        apiUrlV2 +
        "outlets/getZoneDetails?app_id=" +
        appId +
        "&availability=" +
        cateringId +
        "&outletId=" +
        this.state.outlet_id +
        "&ZoneId=" +
        this.state.zone_id;
      axios.get(urlShringTxt).then((res) => {
        if (res.data.status === "ok") {
          var zoneResulSet = res.data.result_set;
          this.setState({
            zoneDetails: zoneResulSet[0],
          });
        }
      });
    }
  }
  getSurCharge() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    axios
      .get(apiUrlCtrng + "orders/getSurCharge?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var surcharge = 0;
          if (
            typeof res.data.total_charge !== "undefined" &&
            res.data.total_charge > 0
          ) {
            surcharge = res.data.total_charge;
          }
          this.setState({ surcharge: surcharge, surchargeLog: res.data.logs });
        }
      });
  }
  getCartDetList() {
    var cart_items = [];
    var cartDisplay = "";
    var disableSlot = [];
    if (this.state.cartItems.length > 0) {
      this.state.cartItems.map((item) => {
        var slotList =
          item.cart_item_breaktime_started +
          "-" +
          item.cart_item_breaktime_ended;
        disableSlot.push(slotList);
        if (
          cart_items[slotList] === "" ||
          typeof cart_items[slotList] === undefined ||
          typeof cart_items[slotList] === "undefined"
        ) {
          cart_items[slotList] = [];
        }
        cart_items[slotList].push(item);
      });

      cartDisplay = (
        <div>
          {this.cartBrkTimeList(cart_items, disableSlot)}
          <ReactTooltip />
        </div>
      );
    }

    this.setState({
      cart_items: cart_items,
      cartDisplay: cartDisplay,
      disableSlot: disableSlot,
    });
  }
  cartBrkTimeList(cart_items, disableSlot) {
    ReactTooltip.rebuild();
    var currentSlot = this.state.currentSlot;
    return Object.keys(cart_items).map((item, index) => {
      var items = cart_items[item];
      var slotListKey = item.split("-");

      return (
        <div className="order_card dark" key={index} id={"slot_" + index}>
          <div
            className="order_card_head"
            onClick={this.selectCurrentSlot.bind(this, index)}
          >
            <h5>
              Order Details {"-"} {parseInt(index) + 1}
            </h5>
            <Link
              onClick={this.deleteCartItm.bind(
                this,
                items[0]["cart_item_id"],
                "alert"
              )}
              to="#"
              title="Delete"
            >
              <img src={delete_white} alt="delete" />
            </Link>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>Event Start Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control start_time"
                      name="start_time"
                      defaultValue={slotListKey[0]}
                      onChange={this.handleUpdateTime.bind(this, item)}
                    >
                      <option value="">Select</option>
                      {this.getbreaktimelist(slotListKey[0], item, disableSlot)}
                    </select>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warningone} alt="warning" />
                </span>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>Event End Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control end_time"
                      name="end_time"
                      defaultValue={slotListKey[1]}
                      onChange={this.handleUpdateTime.bind(this, item)}
                    >
                      <option value="">Select</option>
                      {this.getbreaktimelist(slotListKey[1], item, disableSlot)}
                    </select>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warningone} alt="warning" />
                </span>
              </div>
            </div>
            <div className="products_info">
              {items.length > 0 ? this.cartItemlist(items) : ""}
            </div>
          </div>
        </div>
      );
    });
  }
  selectCurrentSlot(currentSel) {
    if (currentSel === "") {
      currentSel = 0;
    }
    this.setState({ currentSlot: currentSel }, function () {
      this.getCartDetList();
    });
  }
  deleteCartItm(cart_item_id, checkalert) {
    if (checkalert === "alert") {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cart_item_id) {
            showLoader("catering_cart_list", "class");
            this.props.getDeleteCateringCart(cart_item_id);
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    } else {
      if (cart_item_id) {
        this.props.getDeleteCateringCart(cart_item_id);
      } else {
        swal("Error!", "You haven’t added any items yet !", "error");
      }
    }
  }

  handleUpdateTime(slotKey, e) {
    if (e.target.name === "start_time") {
      if (e.target.value === "") {
        this.setState(
          { start_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").show();
      } else {
        this.setState(
          { start_validate: true, event_start: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").hide();
      }
    }

    if (e.target.name === "end_time") {
      if (e.target.value === "") {
        this.setState(
          { end_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").show();
      } else {
        this.setState(
          { end_validate: true, event_end: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").hide();
      }
    }

    this.setState({ [e.target.name]: e.target.value }, function () {
      if (this.state.start_time !== "" && this.state.end_time !== "") {
        this.updateBreakTime(
          slotKey,
          this.state.start_time,
          this.state.end_time
        );
      }
    });
  }
  updateBreakTime(cartItmId, startTime, endTime) {
    var itemList = this.state.cart_items[cartItmId];
    var updateItem = "";
    for (let item in itemList) {
      var itemAssign = itemList[item];
      updateItem += itemAssign.cart_item_id;
      if (item != itemList.length - 1) {
        updateItem + "~~";
      }
    }
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
      cart_item_id: updateItem,
      break_time: startTime,
      break_time_end: endTime,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }
    axios
      .post(apiUrlCtrng + "cart/updateBreakTime", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          this.setState({ start_time: "", end_time: "" });
          swal("Success!", "Nice! Slot updated to your cart", "success");
        } else {
          swal("Error!", "Sorry! Slot can`t update your cart", "error");
        }
      })
      .catch(function (error) {
        swal("Error!", "something went wrong", "error");
      });
  }
  getbreaktimelist(selectedSlot, currentSlot, disable_Slot) {
    var exceptFromSlot = [];
    var exceptToSlot = [];
    if (
      selectedSlot === "" ||
      typeof selectedSlot === undefined ||
      typeof selectedSlot === "undefined"
    ) {
      selectedSlot = "";
    }
    if (
      currentSlot === "" ||
      typeof currentSlot === undefined ||
      typeof currentSlot === "undefined"
    ) {
      currentSlot = "";
    }
    if (
      disable_Slot === "" ||
      typeof disable_Slot === undefined ||
      typeof disable_Slot === "undefined"
    ) {
      disable_Slot = "";
    } else {
      disable_Slot.forEach((value) => {
        if (currentSlot !== value) {
          var timeList = value.split("-");
          exceptFromSlot.push(timeList[0]);
          exceptToSlot.push(timeList[1]);
        }
      });
    }

    var time_slotlist = this.state.time_slotlist;
    var orderDate = this.state.orderDate;
    if (orderDate !== "" && time_slotlist !== "") {
      orderDate = new Date(orderDate);
      var orderDay = orderDate.getDay();
      orderDay = this.state.dayList[orderDay];
      if (
        time_slotlist[orderDay] !== "" &&
        typeof time_slotlist[orderDay] !== undefined &&
        typeof time_slotlist[orderDay] !== "undefined"
      ) {
        if (time_slotlist[orderDay].length > 0) {
          var start = 0;
          var end = 0;
          var hideStart = 0;
          return time_slotlist[orderDay].map((time, index) => {
            if (exceptFromSlot.length > 0) {
              if (exceptFromSlot.indexOf(time) >= 0 && start === 0) {
                start = 1;
                hideStart = 1;
              }
              if (exceptToSlot.indexOf(time) >= 0 && start === 1) {
                start = 0;
                hideStart = 0;
              }
            }
            console.log(hideStart, exceptFromSlot, "hideStarthideStart");
            return (
              <option
                key={index}
                value={time}
                /*  selected={time == selectedSlot ? true : false} */
                disabled={hideStart === 1 ? true : false}
              >
                {time}
              </option>
            );
          });
        }
      }
    }
  }
  cartItemlist(cartItem) {
    const cartlsthtml = cartItem.map((cartLst, index) => (
      <div key={index} className="cart-package-info">
        <div className="products_info_item product-details">
          <div className="cart_left">
            <h4>{stripslashes(cartLst.cart_item_product_name)}</h4>
            <div className="products_info_options">
              <div className="products_info_options_wrap">
                {this.loadModifierItems(cartLst.set_menu_component)}
              </div>
            </div>
          </div>
          <div className="cart_right text-right">
            <span className="cart_indvl_price">
              {Parser(showPriceValue(cartLst.cart_item_total_price))}
            </span>
            <span className="counter cart-counter qty_bx">
              <span
                className="qty_minus cart_qty_minus"
                onClick={this.incQty.bind(
                  this,
                  cartLst.cart_item_id,
                  cartLst.cart_item_product_id,
                  cartLst.cart_item_min_qty,
                  cartLst.cart_item_qty,
                  "minus"
                )}
              >
                -
              </span>
              <strong className="product_quantity">
                {cartLst.cart_item_qty}
              </strong>
              <span
                className="qty_plus cart_qty_plus"
                onClick={this.incQty.bind(
                  this,
                  cartLst.cart_item_id,
                  cartLst.cart_item_product_id,
                  cartLst.cart_item_min_qty,
                  cartLst.cart_item_qty,
                  "plus"
                )}
              >
                +
              </span>
            </span>
          </div>
        </div>
        <div className="cart-settup-list">
          <ul>
            {this.checkSettup(cartLst.setup, "Buffet") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Buffet:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(cartLst.setup, "Buffet")
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="javascript:void(0)"
                      onClick={this.removeCartIndividual.bind(
                        this,
                        cartLst.cart_item_id,
                        "",
                        "setup",
                        "Buffet"
                      )}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Buffet")}
                </div>
              </li>
            )}
            {this.checkSettup(cartLst.setup, "Delivery") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Delivery:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(cartLst.setup, "Delivery")
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeCartIndividual(
                          cartLst.cart_item_id,
                          "",
                          "setup",
                          "Delivery"
                        );
                      }}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Delivery")}
                </div>
              </li>
            )}
            {this.checkSettup(cartLst.setup, "Self Collection") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Self Collection:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(
                            cartLst.setup,
                            "Self Collection"
                          )
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeCartIndividual(
                          cartLst.cart_item_id,
                          "",
                          "setup",
                          "Self Collection"
                        );
                      }}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Self Collection")}
                </div>
              </li>
            )}
            {cartLst.equipment !== "" &&
              cartLst.equipment !== undefined &&
              cartLst.equipment.length > 0 && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>Equipment:</label>
                    <div>
                      <span className="price">
                        {Parser(
                          showPriceValue(
                            this.settuptotalPrice(
                              cartLst.equipment,
                              "Equipment"
                            )
                          )
                        )}
                      </span>
                      <a
                        className="remove cart_remove"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.removeCartIndividual(
                            cartLst.cart_item_id,
                            "",
                            "equipment",
                            "equipment"
                          );
                        }}
                      >
                        <img src={crossImg} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.equipment, "Equipment")}
                  </div>
                </li>
              )}
            {cartLst.addons_setup !== "" &&
              cartLst.addons_setup !== undefined &&
              cartLst.addons_setup.length > 0 && (
                <li>
                  <label>ADD-ONS:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.addons_setup, "Addons")}
                  </div>
                </li>
              )}
          </ul>
        </div>
      </div>
    ));
    return cartlsthtml;
  }
  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.cart_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type !== "" && type !== undefined && type !== "undefined") {
      this.setState({ cartAvailbility: type });
    }
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.cart_equipment_qty} X {settup.cart_equipment_description} (+
            {settup.cart_equipment_price})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="pkgslist_additionrow" key={index}>
            <div className="pkg-list-lhs">
              <label>{settup.cart_addon_setup_title}</label>
              <div>
                <span className="price">
                  {Parser(showPriceValue(settup.cat_addon_setup_amount))}
                </span>
                <a
                  className="remove cart_remove"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.removeCartIndividual(
                      settup.cart_addon_setup_cart_item_id,
                      settup.cart_addon_setup_primary_key,
                      "addons_setup",
                      "addons_setup"
                    );
                  }}
                >
                  <img src={crossImg} alt="" />
                </a>
              </div>
            </div>
            <div className="pkgslist_subaddition">
              {this.loadAddonValues(settup.addons_setup_values)}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.cart_setup_type === type) {
            return (
              <p key={index}>
                {settup.cart_setup_name} ({settup.cart_setup_price}){" "}
              </p>
            );
          }
        });
      }
    }
  }
  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.cart_equipment_totalprice);
      });
    } else {
      setup.map((settup) => {
        if (settup.cart_setup_type === type) {
          totalPrice += parseFloat(settup.cart_setup_tatalprice);
        }
      });
    }
    return totalPrice;
  }
  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.cart_addon_setup_val_title} (
          {addonval.cart_addon_setup_val_qty}X)
        </p>
      );
    });
  }
  loadModifierItems(combo) {
    if (combo.length > 0) {
      return combo.map((item, index) => {
        return (
          <span key={index}>
            <b>{item.menu_component_name}</b>
            <div>{this.showComboProducts(item.product_details)}</div>
          </span>
        );
      });
    }
  }
  showComboProducts(product) {
    if (product.length > 0) {
      return product.map((item, index) => {
        let newPrice =
          item.cart_menu_component_product_price > 0
            ? " (+" + item.cart_menu_component_product_price + ")"
            : "";
        return (
          <>
            <p key={index}>
              {item.cart_menu_component_product_qty} X{" "}
              {item.cart_menu_component_product_name} {newPrice}
            </p>
            {item.modifiers.length > 0
              ? this.showComboModifiers(item.modifiers)
              : ""}
            {item.custom_text !== "" && (
              <div>
                <label className="title">Custom Title</label>
                <div className="products_info_options">
                  <p>{item.custom_text}</p>
                </div>
              </div>
            )}
            {item.custom_logo !== "" && (
              <div>
                <label className="title">Logo</label>
                <div className="products_info_options">
                  <p>{item.custom_logo}</p>
                </div>
              </div>
            )}
          </>
        );
      });
    }
  }
  showComboModifiers(modifiers) {
    if (modifiers.length > 0) {
      return modifiers.map((item, index) => {
        return (
          <div className="modifier_values_list_wrap" key={index}>
            {/*<p>{item.cart_modifier_name}</p>*/}
            {item.modifiers_values.length > 0 && (
              <div className="modifier_values_list">
                {item.modifiers_values.map(function (item1, index1) {
                  let modifier_value_price =
                    parseFloat(item1.cart_modifier_price) > 0
                      ? "(" + item1.cart_modifier_price + ")"
                      : "";
                  return (
                    <p key={index1}>
                      {(parseFloat(item1.cart_modifier_qty)>1)?item1.cart_modifier_qty+' X ':''}{item1.cart_modifier_name}&nbsp;{modifier_value_price}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    }
  }
  incQty(cartItemId, productId, item_min_qty, item_qty, type) {
    let paxIncrease = 10;
    if (type === "plus") {
      item_qty = parseInt(item_qty) + parseInt(paxIncrease);
    } else if (type === "minus") {
      item_qty = parseInt(item_qty) - parseInt(paxIncrease);
    }
    if (item_qty >= item_min_qty) {
      var postObject = {};
      postObject = {
        app_id: appId,
        cart_item_id: cartItemId,
        product_id: productId,
        product_qty: item_qty,
        cartAction: "update",
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postObject["reference_id"] = getReferenceID();
      } else {
        postObject["customer_id"] = cookie.load("UserId");
      }
      var currentObject = this;
      showLoader("catering_cart_list", "class");
      axios
        .post(apiUrlCtrng + "cart/update", qs.stringify(postObject))
        .then((res) => {
          hideLoader("catering_cart_list", "class");
          if (res.data.status === "ok") {
            swal("Success!", res.data.message, "success");
            currentObject.loadCartDet();
          } else if (res.data.status === "error") {
            swal("Error!", res.data.message, "error");
          }
        });
    }
  }
  removeCartIndividual(cartItmId, cartPryId, type, setupType = "") {
    if (
      cartItmId !== "" &&
      (cartPryId !== "" ||
        type === "setup" ||
        type === "equipment" ||
        type === "addons_setup") &&
      type !== ""
    ) {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cartItmId) {
            showLoader("catering_cart_list", "class");
            var postCrtObject = {};
            postCrtObject = {
              app_id: appId,
              cart_item_id: cartItmId,
              p_id: cartPryId,
              type: type,
              setupType: setupType,
            };
            if (typeof cookie.load("UserId") === "undefined") {
              postCrtObject["reference_id"] = getReferenceID();
            } else {
              postCrtObject["customer_id"] = cookie.load("UserId");
            }
            showLoader("catering_cart_list", "class");
            axios
              .post(
                apiUrlCtrng + "cart/delete_individual",
                qs.stringify(postCrtObject)
              )
              .then((res) => {
                hideLoader("catering_cart_list", "class");
                if (res.data.status === "ok") {
                  this.loadCartDet();
                  swal(
                    "Success!",
                    "Nice! Products updated to your cart",
                    "success"
                  );
                } else {
                  swal(
                    "Error!",
                    "Sorry! Products can`t update your cart",
                    "error"
                  );
                }
              })
              .catch(function (error) {
                swal("Error!", "something went wrong", "error");
                hideLoader("catering_cart_list", "class");
              });
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    }
  }
  getCommonSetting(pstCode) {
    var pstCode = pstCode === "" ? this.state.postal_code : "";
    var postCdVal = pstCode !== "" ? "&postal_code=" + pstCode : "";
    axios
      .get(apiUrl + "settings/get_common_settings?app_id=" + appId + postCdVal)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            this.setState({ settings: res.data.result_set });
          }
        }
      });
  }

  postOrder() {
    /* Billing Address Info */
    var billing_add_postalcode = "";
    var billing_add_addressline = "";
    var billing_add_floor_no = "";
    var billing_add_unitno = "";
    if (this.state.same_as_del_address === true) {
      billing_add_postalcode = this.state.delivery_add_postalcode;
      billing_add_addressline = this.state.delivery_add_addressline;
      billing_add_floor_no = this.state.delivery_add_floorno;
      billing_add_unitno = this.state.delivery_add_unitno;
    } else {
      billing_add_postalcode = this.state.billing_add_postalcode;
      billing_add_addressline = this.state.billing_add_addressline;
      billing_add_floor_no = this.state.billing_add_floor_no;
      billing_add_unitno = this.state.billing_add_unitno;
    }
    var validate = true;

    if (billing_add_postalcode === "") {
      $(".billing_add_postalcode_error").show();
      validate = false;
    } else {
      $(".billing_add_postalcode_error").hide();
    }
    if (billing_add_addressline === "") {
      $(".billing_add_addressline_error").show();
      validate = false;
    } else {
      $(".billing_add_addressline_error").hide();
    }
    if (this.state.terms === false) {
      $(".terms_check_error").show();
      validate = false;
    } else {
      $(".terms_check_error").hide();
    }
    if (this.state.orderDate === "") {
      validate = false;
      $(".order_date_error").show();
    } else {
      $(".order_date_error").hide();
    }
    $(".start_time").each(function () {
      if ($(this).val() === "") {
        validate = false;
        $(this).addClass("error");
      } else {
        $(this).removeClass("error");
      }
    });
    $(".end_time").each(function () {
      if ($(this).val() === "") {
        validate = false;
        $(this).addClass("error");
      } else {
        $(this).removeClass("error");
      }
    });
    if (validate === true) {
      var cartItems = this.state.cart_items;
      var breaktime_count = Object.keys(cartItems).length;
      var breaktime_list = Object.keys(cartItems).join();
      var products = [];
      var order_Time = "";
      if (Object.keys(cartItems).length > 0) {
        var order_TimeSplit = Object.keys(cartItems)[0].split("-");
        order_Time = order_TimeSplit[0];
        Object.keys(cartItems).map((slot) => {
          var productList = cartItems[slot];
          productList.map((item) => {
            var menusetdata = [];
            if (item.set_menu_component.length > 0) {
              item.set_menu_component.map((csitem) => {
                var comboproduct_details = [];
                if (csitem.product_details.length > 0) {
                  csitem.product_details.map((cspitem) => {
                    var cmspromodifiers = [];
                    if (cspitem.modifiers.length > 0) {
                      cspitem.modifiers.map((cspmitem) => {
                        var modifiersvaluedata = [];
                        if (cspmitem.modifiers_values.length > 0) {
                          cspmitem.modifiers_values.map((cspmvitem) => {
                            modifiersvaluedata.push({
                              modifier_value_id: cspmvitem.cart_modifier_id,
                              modifier_value_qty: cspmvitem.cart_modifier_qty,
                              modifier_value_name: cspmvitem.cart_modifier_name,
                              modifier_value_price:
                                cspmvitem.cart_modifier_price,
                            });
                          });
                        }
                        cmspromodifiers.push({
                          modifier_id: cspmitem.cart_modifier_id,
                          modifier_name: cspmitem.cart_modifier_name,
                          modifiers_values: modifiersvaluedata,
                        });
                      });
                    }

                    comboproduct_details.push({
                      product_id: cspitem.cart_menu_component_product_id,
                      product_name: cspitem.cart_menu_component_product_name,
                      product_sku: cspitem.cart_menu_component_product_sku,
                      product_qty: cspitem.cart_menu_component_product_qty,
                      product_price: cspitem.cart_menu_component_product_price,
                      modifiers: cmspromodifiers,
                      product_extra_qty:
                        cspitem.cart_menu_component_product_extra_qty,
                      product_extra_price:
                        cspitem.cart_menu_component_product_extra_price,
                      custom_logo: cspitem.custom_logo,
                      custom_text: cspitem.custom_text,
                    });
                  });
                }
                menusetdata.push({
                  menu_component_id: csitem.menu_component_id,
                  menu_component_name: csitem.menu_component_name,
                  product_details: comboproduct_details,
                });
              });
            }

            var setupdata = [];
            if (item.setup.length > 0) {
              item.setup.map((sitem) => {
                setupdata.push({
                  setup_p_id: sitem.cart_setup_primary_key,
                  setup_id: sitem.cart_setup_id,
                  setup_name: sitem.cart_setup_name,
                  setup_type: sitem.cart_setup_type,
                  setup_description: sitem.cart_setup_description,
                  setup_qty: sitem.cart_setup_qty,
                  setup_price: sitem.cart_setup_price,
                  setup_total_price: sitem.cart_setup_tatalprice,
                });
              });
            }
            var equipmentData = [];
            if (item.equipment.length > 0) {
              item.equipment.map((eitem) => {
                equipmentData.push({
                  equipment_p_id: eitem.cart_equipment_primary_key,
                  equipment_id: eitem.cart_equipment_id,
                  equipment_name: eitem.cart_equipment_name,
                  equipment_description: eitem.cart_equipment_description,
                  equipment_qty: eitem.cart_equipment_qty,
                  equipment_price: eitem.cart_equipment_price,
                  equipment_totalprice: eitem.cart_equipment_totalprice,
                });
              });
            }
            var addondata = [];
            if (item.addons_setup.length > 0) {
              item.addons_setup.map((aitem) => {
                var addonvaluedata = [];
                aitem.addons_setup_values.map((avitem) => {
                  addonvaluedata.push({
                    addon_setup_val_id: avitem.cart_addon_setup_val_id,
                    addon_setup_val_title: avitem.cart_addon_setup_val_title,
                    addon_setup_val_desc: avitem.cart_addon_setup_val_desc,
                    addon_setup_val_price: avitem.cart_addon_setup_val_price,
                    addon_setup_val_qty: avitem.cart_addon_setup_val_qty,
                  });
                });
                addondata.push({
                  addon_setup_id: aitem.cart_addon_setup_id,
                  addon_setup_title: aitem.cart_addon_setup_title,
                  addon_setup_selection: aitem.cart_addon_setup_selection,
                  addon_setup_amount: aitem.cat_addon_setup_amount,
                  addons_setup_values: addonvaluedata,
                });
              });
            }
            products.push({
              product_name: item.cart_item_product_name,
              product_unit_price: item.cart_item_unit_price,
              product_total_amount: item.cart_item_total_price,
              product_sku: item.cart_item_product_sku,
              product_image: item.cart_item_product_image,
              product_id: item.cart_item_product_id,
              product_qty: item.cart_item_qty,
              condiments: "",
              modifiers: [],
              bakers_modifiers: "",
              menu_set_components: menusetdata,
              baby_pack: "",
              product_special_notes: item.cart_item_special_notes,
              breaktime_indexflag: item.cart_item_breaktime_indexflag,
              breaktime_started: item.cart_item_breaktime_started,
              breaktime_ended: item.cart_item_breaktime_ended,
              setup: setupdata,
              equipments: equipmentData,
              addons_setup: addondata,
            });
          });
        });
      } else {
        swal("Error!", "Invalid Cart Item", "error");
        return false;
      }
      var promoTionArr = [];
      promoTionArr["promotionApplied"] = "No";
      promoTionArr["promotionAmount"] = "";
      promoTionArr["promoIsDelivery"] = "";
      let dynmaic_surcharge =
        this.state.surcharge !== "" &&
        typeof this.state.surcharge !== undefined &&
        Number(this.state.surcharge) &&
        this.state.surcharge > 0
          ? parseFloat(this.state.surcharge)
          : 0;

      var zoneDetails =
        this.state.zoneDetails !== "" ? [this.state.zoneDetails] : [];

      var settingsArray = this.state.settings;
      if (
        settingsArray.zone_order_value_delivery_charge !== "" &&
        typeof settingsArray.zone_order_value_delivery_charge !== undefined &&
        typeof settingsArray.zone_order_value_delivery_charge !== "undefined"
      ) {
        if (settingsArray.zone_order_value_delivery_charge === "1") {
          zoneDetails[0]["zone_delivery_charge"] =
            this.state.cart_zone_delivery_charge;
        }
      }

      var calculatedAmount = getCateringCalculatedAmount(
        this.state.settings,
        zoneDetails,
        this.state.cartDetailsMain,
        promoTionArr,
        dynmaic_surcharge
      );

      showLoader("order_placed", "class");
      var order_date = this.state.orderDate.split("/");
      var orderdateDetails =
        order_date[2] + "-" + order_date[0] + "-" + order_date[1];
      var postObject = {
        app_id: appId,
        availability_id: cateringId,

        customer_id: cookie.load("UserId"),
        customer_fname: cookie.load("UserFname"),
        customer_lname: cookie.load("UserLname"),
        customer_mobile_no: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: this.state.delivery_add_addressline,
        customer_postal_code: this.state.delivery_add_postalcode,
        customer_unit_no1: this.state.delivery_add_floorno,
        customer_unit_no2: this.state.delivery_add_unitno,

        billing_postal_code: billing_add_postalcode,
        billing_address_line1: billing_add_addressline,
        billing_unit_no1: billing_add_floor_no,
        billing_unit_no2: billing_add_unitno,

        breaktime_enable: "Yes",
        breaktime_count: breaktime_count,
        breaktime_list: breaktime_list,

        outlet_id: this.state.outlet_id,
        zone_id: this.state.zone_id,
        order_tat_time:
          typeof cookie.load("orderTAT") === "undefined"
            ? ""
            : cookie.load("orderTAT"),
        order_date: orderdateDetails + " " + order_Time,
        order_status: 1,
        order_source: "Web",
        order_remarks: this.state.spl_instruction,
        order_data_encode: "Yes",

        order_discount_amount: "",
        order_discount_applied: "",
        order_discount_type: "",

        payment_mode: 1,
        table_number: "",

        products: JSON.stringify(products),

        sub_total: calculatedAmount.cartSubTotalAmount,
        grand_total: calculatedAmount.grandTotalAmount,
        additional_delivery: calculatedAmount.additionalDelivery,
        delivery_charge: calculatedAmount.deliveryCharge,
        order_subcharge_amount: dynmaic_surcharge,
        order_tax_calculate_amount: calculatedAmount.orderGstAmount,
        tax_charge: calculatedAmount.orderDisplayGst,
        surchargeLog:
          this.state.surchargeLog !== ""
            ? JSON.stringify(this.state.surchargeLog)
            : "",
      };
      axios
        .post(apiUrlCtrng + "orders/submit_order", qs.stringify(postObject))
        .then((res) => {
          hideLoader("order_placed", "class");
          if (res.data.status === "ok") {
            $(".order_placed").removeClass("loading-btn");
            var localOrderNo = res.data.common.local_order_no;
            this.showSuccessPage(
              localOrderNo,
              res.data.common.order_primary_id
            );
          } else if (res.data.status === "error") {
            let errormsg = res.data.message;
            if (res.data.form_error !== "") {
              let str = res.data.form_error;
              str = str.replaceAll("<p>", "");
              str = str.replaceAll("</p>", "");
              errormsg = str;
            }
            swal("Error!", errormsg, "error");
          }
        })
        .catch((error) => {
          hideLoader("order_placed", "class");
        });
    } else {
      if ($(".error_scroll:visible").length > 0) {
        $("html, body").animate(
          {
            scrollTop: $(".error_scroll:visible:first").offset().top - 150,
          },
          500
        );
      }
    }
  }

  showSuccessPage(localOrderNo, orderPrimaryId) {
    this.props.destroyCartDetail();
    this.deleteOrderCookie();
    /*this.sendMailFun(orderPrimaryId);*/
    cookie.save("ChkOrderid", localOrderNo);
    const { history } = this.props;
    setTimeout(function () {
      history.push("/catering/thankyou/" + localOrderNo);
    }, 450);
  }

  deleteOrderCookie() {
    cookie.remove("catering_categoryPryId");
    cookie.remove("catering_categoryId");
    cookie.remove("catering_categoryName");
    cookie.remove("catering_zone_id");

    cookie.remove("catering_eventType");
    cookie.remove("cateringOutletId");
    cookie.remove("cateringOutletName");
    cookie.remove("cateringSelectedOutlet");
    cookie.remove("cateringOutletPostalcode");
    cookie.remove("cateringOutletaddressLine");
    cookie.remove("cateringOutletAddress");

    cookie.remove("cateringHallId");
    cookie.remove("cateringHallName");
    cookie.remove("cateringHallPostalcode");
    cookie.remove("cateringHalladdressLine");
    cookie.remove("cateringHalladdress");
    cookie.remove("cateringHallPax");
    cookie.remove("cateringHallCharge");

    cookie.remove("cateringDate");
    cookie.remove("cateringTime");
    cookie.remove("breaktimeEnable");
    cookie.remove("breaktimeCount");
    cookie.remove("breaktimeList");

    cookie.remove("cartTotalItems");
    cookie.remove("fromCkeckOutVld");
    cookie.remove("ChkOrderid");

    cookie.remove("applyCatrngPromo");
    cookie.remove("promoCodeVal");
    cookie.remove("promoIsDelivery");
    cookie.remove("promotionAmount");
    cookie.remove("promotionApplied");
    cookie.remove("promotionSource");
    cookie.remove("promotionType");

    cookie.remove("outlet_id");
    cookie.remove("catering_postal_code");
    cookie.remove("catering_address_line");
    cookie.remove("catering_floor_no");
    cookie.remove("catering_unit_no");
  }

  render() {
    let staticblockData = [];
    if (this.props.staticblockData && this.props.staticblockData.length > 0) {
      staticblockData = this.props.staticblockData.result_set;
    }
    var gstPar = 0;
    var grandTotalAmt = this.state.cartDetailsMain
      ? parseFloat(this.state.cartDetailsMain.cart_grand_total)
      : 0;
    var zoneDetails = this.state.zoneDetails;

    var comSettings = this.state.settings;
    var zone_order_value_delivery_charge = 0;
    if (comSettings && Object.keys(comSettings).length > 0) {
      if (comSettings.zone_order_value_delivery_charge === "1") {
        zone_order_value_delivery_charge = 1;
      }
    }
    var zone_delivery_charge = 0;

    if (zone_order_value_delivery_charge === 1) {
      zone_delivery_charge = this.state.cart_zone_delivery_charge;
    } else {
      zone_delivery_charge =
        zoneDetails.zone_delivery_charge !== "" &&
        typeof zoneDetails.zone_delivery_charge !== undefined
          ? parseFloat(zoneDetails.zone_delivery_charge)
          : 0;
    }
    var zone_additional_delivery_charge =
      zoneDetails.zone_additional_delivery_charge !== "" &&
      typeof zoneDetails.zone_additional_delivery_charge !== undefined
        ? parseFloat(zoneDetails.zone_additional_delivery_charge)
        : 0;
    if (zone_delivery_charge > 0 || zone_additional_delivery_charge > 0) {
      grandTotalAmt =
        parseFloat(grandTotalAmt) +
        parseFloat(zone_delivery_charge) +
        parseFloat(zone_additional_delivery_charge);
    }

    var surcharge =
      this.state.surcharge !== "" &&
      typeof this.state.surcharge !== undefined &&
      typeof this.state.surcharge !== null
        ? parseFloat(this.state.surcharge)
        : 0;

    let {
      sur_deliverysetup_fee,
      sur_ear_deliverysetup_fee,
      sur_lat_teardown_fee,
      sur_deliverysetup_fee_name,
      sur_ear_deliverysetup_fee_name,
    } = getSurchargesplitup(surcharge, this.state.surchargeLog);

    if (surcharge > 0) {
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(surcharge);
    }

    if (comSettings && Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
      var gstAmount = getGstValue(gstPar, grandTotalAmt, "format");
      var gstAmountTxt = getGstValue(gstPar, grandTotalAmt, "");
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(gstAmountTxt);
    }

    return (
      <div className="checkout-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Checkout</p>
          </div>
        </div>
        <div className="container">
          {/* Birthday Info div - start */}
          {this.state.userBirthdate === "" && (
            <div className="birthday-info-div">
              <div className="birthday-info-top">
                <h3>
                  Hi {cookie.load("UserFname")} <img src={hiHndImage} alt="" />
                </h3>
              </div>
              <div className="birthday-info-middle">
                <p>
                  Do share the following for us to reach out to you with
                  promotions and offer!
                </p>
              </div>
            </div>
          )}
          {/* Birthday Info div - end */}
          {/* checkout section Start */}
          <div className="check_out_fourcommon">
            <div className="container-one">
              <div className="checkout_section checkout-total catering-checkout-total">
                <div className="checkout_section_inner cdd-details">
                  <div className="product_orders cdd-details-lhs fl">
                    <div className="product_delivery product-card">
                      <div className="text-center checkout-heading">
                        <span className="text-uppercase">
                          Catering Location & Billing Details{" "}
                        </span>
                      </div>
                      <div className="checkout-body-section">
                        {this.state.cartAvailbility !== "Self Collection" && (
                          <div className="checkout-control-group-top">
                            <label className="chk_hea">Delivery Address</label>
                            <div className="form-group">
                              <div className="focus-out focused controls-single">
                                <input
                                  type="text"
                                  placeholder="Postal Code"
                                  className="form-control"
                                  name="delivery_add_postalcode"
                                  value={this.state.delivery_add_postalcode}
                                  onChange={this.handleTextChange.bind(this)}
                                  disabled
                                />
                                <span
                                  className="error delivery_add_postalcode"
                                  style={{ display: "none" }}
                                >
                                  Please Enter Valid Postal Code
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="focus-out focused controls-single">
                                <input
                                  type="text"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                  name="delivery_add_addressline"
                                  value={this.state.delivery_add_addressline}
                                  onChange={this.handleTextChange.bind(this)}
                                  disabled
                                />
                                <span
                                  className="error catering_address_line"
                                  style={{ display: "none" }}
                                >
                                  Please Enter Valid Address Line
                                </span>
                              </div>
                            </div>
                            <div className="form-group controls-three">
                              <div
                                className={
                                  this.state.delivery_add_floorno != ""
                                    ? "focus-out focused"
                                    : "focus-out"
                                }
                              >
                                <label className="unit-num">Unit No 1</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="delivery_add_floorno"
                                  value={this.state.delivery_add_floorno}
                                  onChange={this.handleTextChange.bind(this)}
                                />
                              </div>
                              <div
                                className={
                                  this.state.delivery_add_unitno != ""
                                    ? "focus-out focused"
                                    : "focus-out"
                                }
                              >
                                <label className="unit-num">Unit No 2</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="delivery_add_unitno"
                                  value={this.state.delivery_add_unitno}
                                  onChange={this.handleTextChange.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="checkout-billing-address">
                          <label className="chk_hea">
                            Billing Address
                            <span>
                              Same As Delivery Address{" "}
                              <div className="custom_checkbox">
                                <input
                                  type="checkbox"
                                  name="same_as_del_address"
                                  onClick={this.handleCheckboxChange.bind(this)}
                                  defaultChecked={
                                    this.state.same_as_del_address
                                  }
                                />
                                <span></span>
                              </div>
                            </span>
                          </label>
                          <div className="check-billing">
                            <div className="form-group">
                              <div className="focus-out controls-single">
                                <input
                                  type="text"
                                  placeholder="Postal Code"
                                  className="form-control"
                                  name="billing_add_postalcode"
                                  id="billing_add_postalcode"
                                  maxLength="6"
                                  value={
                                    this.state.same_as_del_address === true
                                      ? this.state.delivery_add_postalcode
                                      : this.state.billing_add_postalcode
                                  }
                                  onChange={this.handleTextChange.bind(this)}
                                  onKeyPress={(e) => this.validateIntval(e)}
                                  disabled={this.state.same_as_del_address}
                                />
                                <span
                                  className="error billing_add_postalcode_error error_scroll"
                                  style={{ display: "none" }}
                                >
                                  Please enter valid postal code
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="focus-out">
                                <input
                                  type="text"
                                  placeholder="Address Line 1"
                                  name="billing_add_addressline"
                                  className="form-control"
                                  value={
                                    this.state.same_as_del_address === true
                                      ? this.state.delivery_add_addressline
                                      : this.state.billing_add_addressline
                                  }
                                  onChange={this.handleTextChange.bind(this)}
                                  disabled={true}
                                />
                                <span
                                  className="error billing_add_addressline_error error_scroll"
                                  style={{ display: "none" }}
                                >
                                  Please enter valid address
                                </span>
                              </div>
                            </div>
                            <div className="form-group controls-three">
                              <div
                                className={
                                  this.state.same_as_del_address === false
                                    ? this.state.billing_add_floor_no !== ""
                                      ? "focus-out focused"
                                      : "focus-out"
                                    : "focus-out focused"
                                }
                              >
                                <label className="unit-num">Unit No 1</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="billing_add_floor_no"
                                  value={
                                    this.state.same_as_del_address === true
                                      ? this.state.delivery_add_floorno
                                      : this.state.billing_add_floor_no
                                  }
                                  onChange={this.handleTextChange.bind(this)}
                                />
                              </div>
                              <div
                                className={
                                  this.state.same_as_del_address === false
                                    ? this.state.billing_add_unitno !== ""
                                      ? "focus-out focused"
                                      : "focus-out"
                                    : "focus-out focused"
                                }
                              >
                                <label className="unit-num">Unit No 2</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="billing_add_unitno"
                                  value={
                                    this.state.same_as_del_address === true
                                      ? this.state.delivery_add_unitno
                                      : this.state.billing_add_unitno
                                  }
                                  onChange={this.handleTextChange.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="checkout-control-group-bottom">
                          <label className="chk_hea">Special Instruction</label>
                          <textarea
                            className="form-control"
                            placeholder="Please enter your special message here..."
                            name="spl_instruction"
                            onChange={this.handleTextChange.bind(this)}
                          ></textarea>

                          <div className="smcustom_scroll">
                            {(staticblockData || []).map(
                              (staticblock, index) => {
                                if (
                                  staticblock.staticblocks_slug ===
                                  "terms-and-conditions"
                                ) {
                                  return Parser(
                                    stripslashes(
                                      staticblock.staticblocks_description
                                    )
                                  );
                                }
                              }
                            )}
                          </div>
                          <div className="custom_checkbox tarms_chkbox_div">
                            <input
                              type="checkbox"
                              name="terms"
                              onChange={this.handleCheckboxChange.bind(this)}
                            />{" "}
                            <span>
                              I have read and accept terms & conditions
                            </span>
                            <div
                              className="error terms_check_error error_scroll"
                              style={{ display: "none" }}
                            >
                              Please accept Terms & Conditions
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cdd-details-rhs fl">
                    <div className="chekout_cart_bar">
                      <div className="product_orders  cart_body">
                        <div className="product_delivery product-card chekout_cart_bar">
                          <div className="cart-header">
                            <div className="text-center checkout-heading">
                              <span className="text-uppercase">
                                Order Details
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="event_timing event-date">
                              <div className="form-group">
                                <div className="input_field">
                                  <label>Choose Event Date</label>
                                  <div className="datetime-wrap">
                                    <DatePicker
                                      selected={
                                        this.state.orderDate !== ""
                                          ? new Date(this.state.orderDate)
                                          : ""
                                      }
                                      onChange={this.handleChange}
                                      className="form-control order_date"
                                      placeholderText="Event Date"
                                      excludeDates={this.state.disable_dates}
                                      minDate={this.state.mindate}
                                      maxDate={this.state.maxdate}
                                      dateFormat="dd/MM/yyyy"
                                      popperPlacement="bottom-end"
                                    />
                                    <span
                                      className="error order_date_error  error_scroll"
                                      style={{ display: "none" }}
                                    >
                                      Please enter valid address
                                    </span>
                                  </div>
                                </div>
                                <span
                                  data-tip={this.state.delivery_blocks}
                                  data-html={true}
                                  data-class="default_tooltip order_tooltip"
                                  className="tooltip_link"
                                >
                                  <img src={warningone} alt="warning" />
                                </span>
                              </div>
                            </div>
                            <div className="order_det">
                              {this.state.cartDisplay !== ""
                                ? this.state.cartDisplay
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.cartDetailsMain !== "" && (
                        <div className="products_total product-card cart_footer">
                          <div className="cart_footer_inner_item">
                            <div className="products_total_item cart_row">
                              <p className="text-uppercase">Subtotal</p>
                              <span className="total_cart_price">
                                {this.state.cartDetailsMain
                                  ? Parser(
                                      showPriceValue(
                                        this.state.cartDetailsMain
                                          .cart_grand_total
                                      )
                                    )
                                  : ""}
                              </span>
                            </div>
                            {this.state.zoneDetails !== "" &&
                              Object.keys(this.state.zoneDetails).length >
                                0 && (
                                <div className="products_total_item cart_row">
                                  <p className="text-uppercase">
                                    Location surcharge
                                  </p>
                                  <span>
                                    {Parser(
                                      showPriceValue(
                                        parseFloat(zone_delivery_charge) +
                                          parseFloat(
                                            zone_additional_delivery_charge
                                          )
                                      )
                                    )}
                                  </span>
                                </div>
                              )}
                            {parseFloat(this.state.surcharge) > 0 &&
                              sur_deliverysetup_fee > 0 && (
                                <div className="products_total_item cart_row">
                                  <p className="text-uppercase">
                                    Delivery & setup fee{" "}
                                    {sur_deliverysetup_fee_name}
                                  </p>
                                  <span>
                                    {Parser(
                                      showPriceValue(sur_deliverysetup_fee)
                                    )}
                                  </span>
                                </div>
                              )}
                            {parseFloat(this.state.surcharge) > 0 &&
                              sur_ear_deliverysetup_fee > 0 && (
                                <div className="products_total_item cart_row">
                                  <p className="text-uppercase">
                                    Early setup fee / Late setup fee{" "}
                                    {sur_ear_deliverysetup_fee_name}
                                  </p>
                                  <span>
                                    {Parser(
                                      showPriceValue(sur_ear_deliverysetup_fee)
                                    )}
                                  </span>
                                </div>
                              )}
                            {parseFloat(this.state.surcharge) > 0 &&
                              sur_lat_teardown_fee > 0 && (
                                <div className="products_total_item cart_row">
                                  <p className="text-uppercase">
                                    Late teardown fee
                                  </p>
                                  <span>
                                    {Parser(
                                      showPriceValue(sur_lat_teardown_fee)
                                    )}
                                  </span>
                                </div>
                              )}
                            {parseFloat(gstPar) > 0 && (
                              <div className="products_total_item cart_row gst-row">
                                <p className="text-uppercase">
                                  GST ({gstPar}%)
                                </p>
                                <span>{Parser(showPriceValue(gstAmount))}</span>
                              </div>
                            )}
                            <div className="products_total_item products_total_amount cart_row grant-total-cls">
                              <p className="text-uppercase">Total </p>
                              <span>
                                {Parser(showPriceValue(grandTotalAmt))}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Link
                  className="btn btn_dark btn_br btn_upp btn_fill btn_big order_placed"
                  onClick={this.postOrder.bind(this, 1)}
                >
                  Place Your Order Now
                </Link>
              </div>
            </div>
          </div>
          {/* checkout section End */}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZonedetail: () => {
      dispatch({
        type: GET_ZONE_DETAIL,
        outletId: cookie.load("orderOutletId"),
        zoneId: "",
      });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getStaticblock: () => {
      dispatch({
        type: GET_STATIC_BLOCK,
        staticblock_list: "terms-and-conditions",
      });
    },
    getCartdatedata: (obj) => {
      dispatch({ type: GET_CARTDATEDATA, obj: obj });
    },
    getCarttimedata: (obj) => {
      dispatch({ type: GET_CARTTIMEDATA, obj: obj });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    getDeleteCateringCart: (cartItemId) => {
      dispatch({ type: GET_DELETE_CATERING_CART, cartItemId });
    },
  };
};

const mapStateToProps = (state) => {
  var globalSettings = [];
  if (state.settings !== undefined && Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  var activityCountArr = [];
  if (
    state.activitycount !== undefined &&
    Object.keys(state.activitycount).length > 0
  ) {
    if (state.activitycount[0].status === "ok") {
      activityCountArr = state.activitycount[0].result_set;
    }
  }
  let cart_time = [];
  if (state.carttime !== undefined && Object.keys(state.carttime).length > 0) {
    cart_time = state.carttime[0];
  }
  let staticblockData = [];
  if (
    state.staticblack !== undefined &&
    Object.keys(state.staticblack).length > 0
  ) {
    staticblockData = state.staticblack[0];
  }

  return {
    settingsArr: globalSettings,
    zoneDetailData: state.zoneDetailData,
    staticblockData: staticblockData,
    cartdate: state.cartdate,
    carttime: cart_time,
    activitycountArr: activityCountArr,
    deleteDetail: state.deleteCart,
    deleteDetail: state.deleteCateringCart,
  };
};

Checkout = connect(mapStateToProps, mapDispatchToProps)(Checkout);

export default withRouter(Checkout);
