/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import { noimage } from "../Helpers/Config";
class MenuNavigationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
    };
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    let navigateCommon = this.props.navigateCommon;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li
              key={index}
              className={selectedNav === loaddata.pro_cate_slug ? "active" : ""}
            >
              <Link
                to={"/products/category/" + loaddata.pro_cate_slug}
                onClick={(e) => {
                  this.goCategoryProducts();
                }}
                title={loaddata.menu_custom_title}
              >
                <div className="ip-proimg">
                  {loaddata.pro_cate_image !== "" &&
                  loaddata.pro_cate_image !== null ? (
                    <img
                      src={
                        navigateCommon.category_image_url +
                        "/" +
                        loaddata.pro_cate_image
                      }
                      alt={loaddata.menu_custom_title}
                    />
                  ) : (
                    <img src={noimage} alt={loaddata.menu_custom_title} />
                  )}
                </div>
                <div className="ip-protitle">
                  {stripslashes(loaddata.menu_custom_title)}
                </div>
              </Link>
            </li>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <li
              key={index}
              className={selectedNav === loaddata.pro_cate_slug ? "active" : ""}
            >
              <Link
                to={"/products/category/" + loaddata.pro_cate_slug}
                onClick={(e) => {
                  this.goCategoryProducts();
                }}
                title={loaddata.menu_custom_title}
              >
                <div className="ip-proimg">
                  <img src={noimage} />
                </div>
                <div className="ip-protitle">
                  {stripslashes(loaddata.menu_custom_title)}
                </div>
              </Link>
            </li>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts() {
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );
  }

  render() {
    return <ul>{this.navigateMenuList()}</ul>;
  }
}

export default MenuNavigationTab;
