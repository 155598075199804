import { SET_HALL_CATEGORY } from '../actions';

const cateringhallcategory = (state = [], action) => {
  switch (action.type) {
    case SET_HALL_CATEGORY:
      return [...action.value];
    default: return state;
  }
}

export default cateringhallcategory;