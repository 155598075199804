/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { appId, apiUrlV2, deliveryId, noimage } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  shorten,
  createScriptForAddToCart,
} from "../../Helpers/SettingHelper";
var qs = require("qs");
var Parser = require("html-react-parser");

import {
  GET_FEATUREPRO,
  GET_FAVOURITE,
  GET_FAVOURITELIST,
} from "../../../actions";
import cartLike from "../../../common/images/heart-black.svg";
import cartLikeActive from "../../../common/images/heart-bg-red.svg";
import { isNull } from "underscore";
import OwlCarousel from "react-owl-carousel2";

class PantryProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      favproductID: [],
      favdeleteproductID: [],
      favouriteproduct: [],
    };
    this.props.getFavouriteproductslist();
  }

  componentWillMount() {
    this.props.getFeatureProList();
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    if (customerId !== "") {
      var formData = {
        app_id: appId,
        customer_id: cookie.load("UserId"),
      };
      var postObject = qs.stringify(formData);
      this.props.getFavouriteproducts(postObject);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.favouriteproduct !== nextProps.favouriteproduct) {
      this.setState(
        { favouriteproduct: nextProps.favouriteproduct },
        function () {
          this.props.getFavouriteproductslist();
        }
      );
    }
  }

  addFavourite(productID, fav_flag) {
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    if (customerId !== "") {
      var formData = {
        app_id: appId,
        product_id: productID,
        fav_flag: fav_flag,
        customer_id: cookie.load("UserId"),
      };
      var favproductID = this.state.favproductID;
      var favdeleteproductID = this.state.favdeleteproductID;
      if (fav_flag === "Yes") {
        favproductID.push(productID);
        const index1 = favdeleteproductID.indexOf(productID);
        if (index1 > -1) {
          favdeleteproductID.splice(index1, 1);
        }
      } else {
        const index = favproductID.indexOf(productID);
        if (index > -1) {
          favproductID.splice(index, 1);
        }
        favdeleteproductID.push(productID);
      }
      this.setState({
        favproductID: favproductID,
        favdeleteproductID: favdeleteproductID,
      });

      var postObject = qs.stringify(formData);
      this.setState({ favouriteproduct: [] });
      this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndexfea-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);

    if (actionFlg === "decr" && proqtyInput == 1) {
      $("#proIndex-" + indxFlg)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + indxFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + indxFlg)
        .find(".product-list-main-div")
        .removeClass("add-cart");
    }
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndexfea-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndexfea-" + IndexFlg).addClass("active");
        $("#proIndexfea-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndexfea-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        $("#proIndex-" + IndexFlg)
          .find(".product-list-main-div")
          .addClass("add-cart");
      } else {
        showLoader("proIndexfea-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var userAccountType =
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType");
        var proqtyQty = $("#proIndexfea-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          login_type: userAccountType,
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndexfea-" + IndexFlg).removeClass("active");
          hideLoader("proIndexfea-" + IndexFlg, "Idtext");
          $("#proIndexfea-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndexfea-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + IndexFlg)
            .find(".product-list-main-div")
            .removeClass("add-cart");
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
              createScriptForAddToCart();
            }
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        /*  $("#proIndexfea-" + productDetail.product_primary_id).addClass(
          "active"
        ); */
        this.props.history.push(
          "/menu/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productDetail.product_slug
        );
        // this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let featureproArr = this.props.featureproduct;
    let featureprolist = [];
    let featureproimagesource = "";
    let tagimagesource = "";
    if (Object.keys(featureproArr).length > 0) {
      if (featureproArr[0].status === "ok") {
        featureprolist = featureproArr[0].result_set;
        featureproimagesource = featureproArr[0].common.image_source;
        tagimagesource = featureproArr[0].common.tag_image_source;
      }
    }

    var settingsJourney = {
      items: 4,
      autoplay: false,
      margin: 18,
      loop: false,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        580: {
          items: 2,
          dots: true,
        },
        780: {
          items: 3,
          dots: true,
        },
        1280: {
          items: 4,
          dots: true,
        },
      },
    };

    return (
      <>
        {Object.keys(featureprolist).length > 0 && (
          <section className="home-pantry">
            <section className="featuredpro-section">
              <div className="featured-products-section">
                <div className="container">
                  {this.props.homePantryBlk}
                  <div className="featured-products-main">
                    <ul className="products-list-ulmain">
                      <OwlCarousel options={settingsJourney}>
                        {featureprolist.map((featurepro, index) => {
                          var prodivId =
                            "proIndexfea-" + featurepro.product_primary_id;
                          var comboProId =
                            "comboPro-" + featurepro.product_slug;
                          return (
                            <li
                              className={
                                featurepro.product_tag_info !== "" &&
                                featurepro.product_tag_info !== null
                                  ? "products-single-li offer_tag_show"
                                  : "products-single-li"
                              }
                              id={prodivId}
                              key={index}
                            >
                              <div
                                className={
                                  featurepro.product_tag_info !== "" &&
                                  featurepro.product_tag_info !== null
                                    ? "box-outer product-list-main-div"
                                    : "product-list-main-div"
                                }
                              >
                                {featurepro.product_tag_info !== "" &&
                                  featurepro.product_tag_info !== null && (
                                    <div className="sale-flag-sash">
                                      <span className="sale-text">
                                        {featurepro.product_tag_info}
                                      </span>
                                    </div>
                                  )}

                                {/* {this.state.customerId !== "" ? (
                              <div>
                                {this.state.favdeleteproductID.length > 0 &&
                                this.state.favdeleteproductID.indexOf(
                                  featurepro.product_primary_id
                                ) >= 0 ? (
                                  <a
                                    href={void 0}
                                    className="like"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      featurepro.product_primary_id,
                                      "Yes"
                                    )}
                                  >
                                    <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                  </a>
                                ) : (featurepro.fav_product_primary_id !== null && featurepro.fav_product_primary_id !== '' &&
                                    typeof featurepro.fav_product_primary_id !==
                                      undefined &&
                                    typeof featurepro.fav_product_primary_id !==
                                      "undefined") ||
                                  this.state.favproductID.indexOf(
                                    featurepro.product_primary_id
                                  ) >= 0 ? (
                                  <a
                                    href={void 0}
                                    className="like active"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      featurepro.product_primary_id,
                                      "No"
                                    )}
                                  >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart" />
                                  </a>
                                ) : (
                                  <a
                                    href={void 0}
                                    className="like"
                                    onClick={this.addFavourite.bind(
                                      this,
                                      featurepro.product_primary_id,
                                      "Yes"
                                    )}
                                  >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                  </a>
                                )}
                              </div>
                            ) : (
                              <div>
                                <a
                                  href={void 0}
                                  className="like"
                                  onClick={this.addFavourite.bind(
                                    this,
                                    featurepro.product_primary_id,
                                    "Yes"
                                  )}
                                >
                                  <img src={cartLike} className="no_mou" />
                                    <img src={cartLikeActive} className="active-hart mou_active" />
                                </a>
                              </div>
                            )}   */}

                                <div className="products-image-div">
                                  {featurepro.product_thumbnail !== "" &&
                                  featurepro.product_thumbnail !== null ? (
                                    <img
                                      src={
                                        featureproimagesource +
                                        "/" +
                                        featurepro.product_thumbnail
                                      }
                                      alt={
                                        featurepro.product_alias !== ""
                                          ? stripslashes(
                                              featurepro.product_alias
                                            )
                                          : featurepro.product_name
                                          ? stripslashes(
                                              featurepro.product_name
                                            )
                                          : ""
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={noimage}
                                      alt={
                                        featurepro.product_alias !== ""
                                          ? stripslashes(
                                              featurepro.product_alias
                                            )
                                          : featurepro.product_name
                                          ? stripslashes(
                                              featurepro.product_name
                                            )
                                          : ""
                                      }
                                    />
                                  )}
                                </div>

                                <div className="product-info-div">
                                  <div className="product-title-maindiv">
                                    <div className="product-title">
                                      <h3>
                                        {featurepro.product_alias !== ""
                                          ? stripslashes(
                                              featurepro.product_alias
                                            )
                                          : featurepro.product_name
                                          ? stripslashes(
                                              featurepro.product_name
                                            )
                                          : ""}
                                      </h3>
                                    </div>
                                    <div className="product-short-description">
                                      <div className="product-item-img">
                                        {featurepro.product_tag.length > 0 && (
                                          <>
                                            {featurepro.product_tag.map(
                                              (producttag, index1) => {
                                                return (
                                                  <img
                                                    src={
                                                      tagimagesource +
                                                      "/" +
                                                      producttag.pro_tag_image
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                      </div>

                                      {featurepro.product_short_description !=
                                        "" &&
                                      featurepro.product_short_description !=
                                        null
                                        ? Parser(
                                            stripslashes(
                                              shorten(
                                                featurepro.product_short_description
                                              )
                                            )
                                          )
                                        : Parser("")}
                                    </div>
                                  </div>
                                  {/*featurepro.product_cost > 0 && (
                              <h4 className="original-price">
                                {showPriceValue(featurepro.product_cost)}
                              </h4>
                            )*/}
                                  <div className="products-ordernow-action">
                                    <div className="product-price">
                                      {featurepro.product_specialprice_applicable ===
                                        "yes" &&
                                        (parseFloat(featurepro.product_price) >
                                        0 ? (
                                          <span className="price_disc">
                                            {showPriceValue(
                                              featurepro.product_price
                                            )}
                                          </span>
                                        ) : (
                                          parseFloat(featurepro.product_cost) >
                                            0 && (
                                            <span className="price_disc">
                                              {showPriceValue(
                                                featurepro.product_cost
                                              )}
                                            </span>
                                          )
                                        ))}
                                      <h3>
                                        {featurepro.product_specialprice_applicable ===
                                        "yes"
                                          ? showPriceValue(
                                              featurepro.product_special_price,
                                              "Y"
                                            )
                                          : showPriceValue(
                                              featurepro.product_price
                                            )}
                                      </h3>
                                    </div>

                                    <div className="addcart_row addcart_done_maindiv">
                                      <div className="addcart-row-child">
                                        <div className="qty_bx">
                                          <span
                                            className="qty_minus"
                                            onClick={this.proQtyAction.bind(
                                              this,
                                              featurepro.product_primary_id,
                                              "decr"
                                            )}
                                          >
                                            -
                                          </span>
                                          <input
                                            type="text"
                                            className="proqty_input"
                                            readOnly
                                            value="1"
                                          />
                                          <span
                                            className="qty_plus"
                                            onClick={this.proQtyAction.bind(
                                              this,
                                              featurepro.product_primary_id,
                                              "incr"
                                            )}
                                          >
                                            +
                                          </span>
                                        </div>
                                        <button
                                          className="button btn btn_black order_done"
                                          onClick={this.addToCartSimple.bind(
                                            this,
                                            featurepro,
                                            "done"
                                          )}
                                        >
                                          Done
                                        </button>
                                      </div>
                                    </div>

                                    {featurepro.product_stock > 0 ||
                                    featurepro.product_stock === null ? (
                                      featurepro.product_type === "1" ? (
                                        <a
                                          href="/"
                                          onClick={this.viewProDetail.bind(
                                            this,
                                            featurepro
                                          )}
                                          title="Product Details"
                                          id={comboProId}
                                          className="button order_nowdiv compo_product_lk"
                                        >
                                          Add to Cart
                                        </a>
                                      ) : (
                                        <a
                                          href="/"
                                          onClick={this.viewProDetail.bind(
                                            this,
                                            featurepro
                                          )}
                                          title="Product Details"
                                          id={comboProId}
                                          className="button order_nowdiv compo_product_lk"
                                        >
                                          Add to Cart
                                        </a>
                                      )
                                    ) : (
                                      <a
                                        className="button disabled disbl_href_action"
                                        href="/"
                                      >
                                        Sold Out
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </OwlCarousel>
                    </ul>
                  </div>
                  <div className="home-bottom-link">
                    <Link to={"/menu"}>Shop More</Link>
                  </div>
                </div>
              </div>
            </section>
          </section>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var favouriteproductlist = Array();
  var productCmn = Array();

  if (Object.keys(state.favouriteproductlist).length > 0) {
    var tempArr = state.favouriteproductlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        favouriteproductlist = tempArr[0].result_set;
        productCmn = tempArr[0].common;
      } else if (tempArr[0].status === "error") {
        $("#dvLoading").fadeOut(1000);
      }
    }
  }

  return {
    featureproduct: state.featureproduct,
    favouriteproduct: state.favouriteproduct,
    productSubcatlist: favouriteproductlist,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
    getFavouriteproductslist: () => {
      dispatch({ type: GET_FAVOURITELIST });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(PantryProducts);
