import { SET_HIGHLIGHTPROLIST } from "../actions";

const highlightproductlist = (state = [], action) => {
  switch (action.type) {
    case SET_HIGHLIGHTPROLIST:
      return [...action.value];
    default:
      return state;
  }
};

export default highlightproductlist;
