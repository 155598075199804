import { takeEvery, call, put } from "redux-saga/effects";
import { GET_UPLOAD_IMAGE, SET_UPLOAD_IMAGE } from "../actions";
import { appId, apiUrlCtrng } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetUploadImage = function* () {
  yield takeEvery(GET_UPLOAD_IMAGE, workerGetUploadImage);
};

function* workerGetUploadImage(obj) {
  try {
    const uri = apiUrlCtrng + "cart/uploadImg?app_id=" + appId;
    const result = yield call(Axios.post, uri, obj.obj);
    console.log(result, "resultresultresultresult");
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_UPLOAD_IMAGE, value: resultArr });
  } catch {
    console.log("Get Upload Logo Failed");
  }
}
